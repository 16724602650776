import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { AddRounded } from '@material-ui/icons';
import { Button, Grid, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import { Link, useRouteMatch } from 'react-router-dom';
import { useStyles } from './ServiceTables.styles';
import theme from '../../../../style/theme';

interface IServiceTableRows {
  icon: React.ReactNode;
  name: string;
  registered: number;
  spacesLeft?: number;
  link: string;
}

// const rows: IServiceTableRows[] = [
//   {
//     icon: Categories[0].icon,
//     name: Categories[0].title,
//     registered: 2,
//     spacesLeft: 0,
//     link: `${Categories[0].id}`,
//   },
//   {
//     icon: Categories[1].icon,
//     name: Categories[1].title,
//     registered: 5,
//     spacesLeft: 0,
//     link: `${Categories[1].id}`,
//   },
//   {
//     icon: Categories[2].icon,
//     name: Categories[2].title,
//     registered: 5,
//     spacesLeft: 1,
//     link: `${Categories[2].id}`,
//   },
//   {
//     icon: Categories[3].icon,
//     name: Categories[3].title,
//     registered: 5,
//     link: `${Categories[3].id}`,
//   },
//   {
//     icon: Categories[4].icon,
//     name: Categories[4].title,
//     registered: 5,
//     link: `${Categories[4].id}`,
//   },
//   {
//     icon: Categories[5].icon,
//     name: Categories[5].title,
//     registered: 5,
//     spacesLeft: 0,
//     link: `${Categories[5].id}`,
//   },
// ];

export interface ServiceTableProps {
  rows: IServiceTableRows[];
}

export const ServiceTable = (props: ServiceTableProps) => {
  const { rows } = props;
  const classes = useStyles();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { path } = useRouteMatch();

  return (
    <div className={classes.container}>
      <Table aria-label="Tjänster">
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell>
              <Typography variant={!mobileView ? 'h2' : 'body1'} className={classes.title}>
                Tjänster
              </Typography>
            </TableCell>
            <TableCell>Registrerade</TableCell>
            <TableCell>Kvar att registrera</TableCell>
            <TableCell>
              <span className="sr-only">Knappar</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: IServiceTableRows) => (
            <TableRow key={row.name}>
              <TableCell>
                <Typography variant={!mobileView ? 'h4' : 'body1'} component="span">
                  <Grid container wrap="nowrap" alignItems="center">
                    {!mobileView && <div className={classes.icon}>{row.icon} </div>}
                    {row.name}
                  </Grid>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={`${classes.number} ${row.registered > 0 ? classes.availableSpaces : ''}`}
                  variant={!mobileView ? 'h3' : 'body1'}
                  component="span"
                >
                  {row.registered}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.number} variant={!mobileView ? 'h3' : 'body1'} component="span">
                  {row.spacesLeft || row.spacesLeft === 0 ? row.spacesLeft : 'Obegränsat'}
                </Typography>
              </TableCell>

              <TableCell className={classes.action}>
                {!mobileView ? (
                  <Button
                    startIcon={<AddRounded />}
                    component={Link}
                    to={`${path}/registrer/${row.link}`}
                    color="primary"
                    variant="contained"
                  >
                    Registera
                  </Button>
                ) : (
                  <Tooltip title="Registera">
                    <Button
                      className={classes.iconButton}
                      aria-label="Registera"
                      component={Link}
                      to={`${path}/registrer/${row.link}`}
                      color="primary"
                      variant="contained"
                    >
                      <AddRounded />
                    </Button>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
