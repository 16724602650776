import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';
import { Box, InputBase, Typography } from '@material-ui/core';
import { AppContext } from '../../states/AppState';
import { Link, useHistory } from 'react-router-dom';
import logo from '../../assets/logos/logo_black.svg';
import { useStyles } from './LoginPage.styles';
import { useLogin } from '../../Fetchers';
import { CookieBanner } from '../../components/cookieBanner/CookieBanner';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Card } from '../../components/card/Card';

export const LoginPage = () => {
  const classes = useStyles();
  const {
    dispatch,
    state: { seenCookieBanner },
  } = useContext(AppContext);
  const { register, handleSubmit, errors, setError } = useForm();
  const { mutate: login, loading } = useLogin({});
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (!executeRecaptcha) {
        return setError('Password', { message: 'Tekniskt fel' });
      }

      const token = await executeRecaptcha();
      const result = await login({ ...data, captchaToken: token });
      if (result.loggedInUser?.name) {
        dispatch({ type: 'UPDATE_USER_DETAILS', payload: { name: result.loggedInUser.name } });
      } else {
        throw new Error();
      }
    } catch (error) {
      return setError('Password', { message: 'Fel Personummer/lösenord' });
    }
    history.replace(
      history.location.pathname === '/' || history.location.pathname === '/login'
        ? '/dashboard'
        : history.location.pathname,
    );
  });

  return (
    <Grid className={classes.container} container justify="center" alignItems="center" component="main">
      {/* <GoogleReCaptchaProvider
            reCaptchaKey="6LfclAsaAAAAABw0RNFW6sL3S0lfy23_N7UnX4q0"
            scriptProps={{ async: true, defer: true, appendTo: 'body' }}
          >  */}
      <Typography variant="h1" className="sr-only">
        Logga in
      </Typography>
      <Grid lg={3} md={3} sm={5} xs={12} item>
        <Card
          header={{
            title: 'Välkommen till nya spärrtjänsten!',
          }}
          disableMaxHeight
        >
          <Typography>
            Nu har det kommit en uppdatering av spärrtjänsten med ett nytt utseende och bakomliggande system. På grund
            av ombyggnationen har inloggningsuppgifterna nollställts. Du kan därmed behöva använda glömt lösenords
            länken här nedanför för att sätta ett nytt lösenord. Kontakta kundtjänst info@keycode.se vid problem.
          </Typography>
        </Card>
      </Grid>
      <Grid container justify="center">
        <Grid item xl={2} lg={3} md={3} sm={5} xs={12}>
          <Box boxShadow={4} className={classes.root}>
            <img className={classes.logo} src={logo} alt="Logotyp för Keycode" />

            <form className={classes.form} noValidate onSubmit={onSubmit}>
              <label className={classes.label} htmlFor="identification-number">
                Personnummer
              </label>
              <InputBase
                inputRef={register}
                required
                fullWidth
                className={classes.input}
                id="identification-number"
                placeholder="ÅÅÅÅMMDDXXXX"
                name="IdentificationNumber"
                autoComplete="identificationNumber"
                autoFocus
              />
              <label className={classes.label} htmlFor="password">
                Lösenord
              </label>
              <InputBase
                className={`${classes.input} ${!errors.Password ? classes.pass : ''}`}
                required
                fullWidth
                inputRef={register}
                name="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {errors.Password && (
                <Typography variant="body2" className={classes.error}>
                  {errors.Password.message}
                </Typography>
              )}
              <Grid container justify="flex-end" className={classes.forgot}>
                <Typography variant="body2">
                  <Link className={classes.link} to="/forgotpassword">
                    Glömt lösenord?
                  </Link>
                </Typography>
              </Grid>
              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={loading}
              >
                Logga in
              </Button>
              <Button
                size="large"
                className={classes.button}
                fullWidth
                component={Link}
                to="/registeruser"
                variant="contained"
              >
                Registrera nytt konto
              </Button>
              <Link className={classes.link} to="/activationcodelogin">
                Logga in med aktiveringskod
              </Link>
              {/* <PublicRoute/> */}
            </form>
          </Box>
        </Grid>
      </Grid>
      {seenCookieBanner !== 'true' && (
        <CookieBanner onClose={() => dispatch({ type: 'UPDATE_SEEN_COOKIE_BANNER', payload: 'true' })} />
      )}
      {/* </GoogleReCaptchaProvider> */}
    </Grid>
  );
};
