import { useContext, useEffect } from 'react';
import { useGetAllCardCategoryGroups, useGetUser } from '../Fetchers';
import { AppContext } from '../states/AppState';

interface IProps {
  lazy?: boolean;
}

export const useGetUserDetails = (props: IProps) => {
  const { dispatch } = useContext(AppContext);
  const { data, loading, refetch } = useGetUser({ lazy: props.lazy });
  const { data: categoryGroupsResult } = useGetAllCardCategoryGroups({});

  useEffect(() => {
    if (data && !loading) {
      dispatch({ type: 'UPDATE_USER_DETAILS', payload: data?.userDetails });
    }
  }, [data, dispatch, loading]);

  useEffect(() => {
    if (categoryGroupsResult?.cardCategoryGroups) {
      dispatch({ type: 'UPDATE_CATEGORY_GROUPS', payload: categoryGroupsResult.cardCategoryGroups });
    }
  }, [categoryGroupsResult, dispatch]);

  return { refetch };
};
