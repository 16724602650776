import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '../../components/routes/PrivateRoute';
import { BlockservicePage } from './subpages/BlockservicePage';
import { RegisterItemPage } from './subpages/registerItemPage/RegisterItemPage';

export function BlockserviceRoute() {
  return (
    <Switch>
      <PrivateRoute
        exact
        component={BlockservicePage}
        path="/blockservice"
        breadcrumbs={[
          { title: 'Mina tjänster' },
          {
            title: 'Spärrtjänster',
            to: '/blockservice',
          },
        ]}
      />
      <PrivateRoute
        component={RegisterItemPage}
        path="/blockservice/registrer/:id"
        exact
        breadcrumbs={[
          { title: 'Mina tjänster' },
          {
            title: 'Spärrtjänster',
            to: '/blockservice',
          },
          {
            title: 'Kort',
          },
        ]}
      />
    </Switch>
  );
}
