import { Grid, Typography, Box, InputBase, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { Required } from '../../components/required/Required';
import { ReturnToLogin } from '../../components/returntologin/ReturnToLogin';
import { useRegisterUser } from '../../Fetchers';
import { useStyles } from '../login/LoginPage.styles';

export const RegisterUserPage = () => {
  const classes = useStyles();

  const { register, handleSubmit, errors, setError } = useForm();
  const { mutate: RegisterUser, loading } = useRegisterUser({});
  const [showSuccess, setShowSuccess] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = handleSubmit(async (data: any) => {
    if (!executeRecaptcha) {
      return setError('Verifypassword', { message: 'Tekniskt fel' });
    }

    if (data.Password !== data.Verifypassword) {
      return setError('Verifypassword', { message: 'Lösenordet måste matcha' });
    }

    try {
      const token = await executeRecaptcha();
      await RegisterUser({ ...data, captchaToken: token });
      setShowSuccess(true);
    } catch (error) {
      if (error.status === 400) {
        if (error?.data?.type === 'validation') {
          error?.data?.errors.forEach((x: { name: string; error: string }) => setError(x.name, { message: x.error }));
        } else {
          setError('Verifypassword', { message: 'Länken är ogiltlig' });
        }
      } else if (error.status === 403) {
        setError('Verifypassword', { message: 'Länken är för gammal' });
      }
    }
  });

  return (
    <Grid className={classes.container} container justify="center" alignItems="center" component="main">
      <Grid item xl={2} lg={3} md={3} sm={5} xs={12}>
        <Box boxShadow={4} className={classes.root}>
          <Grid container justify="center">
            <Grid container item justify="flex-start">
              <Typography variant="h1" align="left" className={classes.header}>
                Registera dig
              </Typography>
            </Grid>
            {!showSuccess ? (
              <form className={classes.form} noValidate onSubmit={onSubmit}>
                <label className={classes.label} htmlFor="ActivationCode">
                  Aktiveringskod
                  <Required />
                </label>
                <InputBase
                  className={classes.input}
                  required
                  fullWidth
                  inputRef={register({ required: { value: true, message: 'Måste vara ifyllt' } })}
                  name="ActivationCode"
                  id="ActivationCode"
                />
                {errors.ActivationCode && (
                  <Typography className={classes.error}>{errors.ActivationCode.message}</Typography>
                )}
                <label className={classes.label} htmlFor="Name">
                  Namn
                  <Required />
                </label>
                <InputBase
                  className={classes.input}
                  required
                  fullWidth
                  inputRef={register({ required: { value: true, message: 'Måste vara ifyllt' } })}
                  name="Name"
                  id="Name"
                />
                {errors.Name && <Typography className={classes.error}>{errors.Name.message}</Typography>}
                <label className={classes.label} htmlFor="Email">
                  E-post
                  <Required />
                </label>
                <InputBase
                  className={classes.input}
                  required
                  fullWidth
                  placeholder="user@test.com"
                  inputRef={register({
                    pattern: { value: /^\S+@\S+\.\S+$/, message: 'Måste vara en giltlig epost' },
                    required: 'Måste vara ifyllt',
                  })}
                  name="Email"
                  id="Email"
                />
                {errors.Email && <Typography className={classes.error}>{errors.Email.message}</Typography>}
                <label className={classes.label} htmlFor="IdentificationNumber">
                  Personnummer
                  <Required />
                </label>
                <InputBase
                  className={classes.input}
                  required
                  fullWidth
                  inputRef={register({
                    required: 'Måste vara ifyllt',
                    pattern: {
                      value: /^[\d]{12}$/,
                      message: 'Måste vara tolv siffor',
                    },
                  })}
                  name="IdentificationNumber"
                  placeholder="ÅÅÅÅMMDDXXXX"
                  id="IdentificationNumber"
                />
                {errors.IdentificationNumber && (
                  <Typography className={classes.error}>{errors.IdentificationNumber.message}</Typography>
                )}
                <label className={classes.label} htmlFor="PhoneNumber">
                  Telefonnummer
                </label>
                <InputBase
                  className={classes.input}
                  required
                  fullWidth
                  inputRef={register()}
                  name="PhoneNumber"
                  id="PhoneNumber"
                />
                <label className={classes.label} htmlFor="Password">
                  Lösenord
                  <Required />
                </label>
                <InputBase
                  type="password"
                  className={classes.input}
                  required
                  fullWidth
                  inputRef={register({
                    required: { value: true, message: 'Måste vara ifyllt' },
                    minLength: {
                      value: 8,
                      message: 'Minst 8 tecken',
                    },
                  })}
                  placeholder="Minst 8 tecken"
                  name="Password"
                  id="Password"
                />
                {errors.Password && <Typography className={classes.error}>{errors.Password.message}</Typography>}
                <label className={classes.label} htmlFor="Verifypassword">
                  Verifiera lösenord
                  <Required />
                </label>
                <InputBase
                  className={classes.input}
                  type="password"
                  required
                  fullWidth
                  inputRef={register({
                    required: { value: true, message: 'Måste vara ifyllt' },
                    minLength: {
                      value: 8,
                      message: 'Minst 8 tecken',
                    },
                  })}
                  name="Verifypassword"
                  id="Verifypassword"
                />
                {errors.Verifypassword && (
                  <Typography className={classes.error}>{errors.Verifypassword.message}</Typography>
                )}
                {showSuccess ? (
                  <Redirect to="/login" />
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={loading}
                  >
                    Registrera användare
                  </Button>
                )}
              </form>
            ) : (
              <ReturnToLogin message="Ditt konto är nu registrerat!" />
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
