import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem',
    },
  },
}));

export const PageSection: React.FC = (props) => {
  const classes = useStyles();
  return <section className={classes.root}>{props.children}</section>;
};
