import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './Label.styles';

interface ILabelProps {
  label: string;
  helperText?: string;
  component?: 'label' | 'span' | 'legend';
}

export const Label = (props: ILabelProps) => {
  const classes = useStyles();
  const labelWithoutSpaces = props.label?.replace(/ /g, '');

  return (
    <>
      <Typography
        component={props.component ? props.component : 'label'}
        className={classes.label}
        htmlFor={labelWithoutSpaces}
      >
        <span>{props.label}</span>
        {props.helperText && <span className={classes.helperText}>{props.helperText}</span>}
      </Typography>
    </>
  );
};
