import React, { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { PageHeader } from '../../components/pageHeader/PageHeader';
import { FeatureCard } from './components/featureCard/FeatureCard';
import { PageSection } from '../../components/pageSection/PageSection';
import { BlockRounded, Home } from '@material-ui/icons';
import { IconProfile } from './components/iconProfile/IconProfile';
import { useStyles } from './DashboardPage.styles';
import { AppContext } from '../../states/AppState';

export const DashboardPage = () => {
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const { name, email, phoneNumber } = state.userDetails;

  return (
    <>
      <PageHeader title={`Välkommen ${name}!`} icon={<Home />}>
        <Typography>Här kan du själv hantera Keycodes tjänster för din personliga trygghet.</Typography>
        <Typography>
          Tveka inte att <strong>kontakta oss</strong>, om du behöver hjälp!
        </Typography>
      </PageHeader>
      <PageSection>
        <Grid container component="ul" className={classes.list} justify="flex-start" aria-label="Tjänster" spacing={3}>
          <FeatureCard
            title="Spärrtjänst"
            link={{
              text: 'Gå till tjänsten',
              to: '/blockservice',
            }}
            icon={<BlockRounded />}
          >
            <Typography>
              Spärrtjänst är ett kortskydd som skyddar dina värdehandlingar inklusive mobiltelefon. Om olyckan är framme
              ringer du bara ett enda samtal för att spärra dina olika kort och värdehandlingar.
            </Typography>
          </FeatureCard>
          <FeatureCard
            title="Mina personuppgifter"
            link={{
              text: 'Ändra uppgifter',
              to: '/settings',
            }}
            icon={<IconProfile />}
          >
            <Typography>{name}</Typography>
            <div className={classes.contact}>
              <Typography className={classes.contactTitle}>Email</Typography>
              <Typography>{email}</Typography>
            </div>
            <div className={classes.contact}>
              <Typography className={classes.contactTitle}>Telefon</Typography>
              <Typography>{phoneNumber}</Typography>
            </div>
          </FeatureCard>
        </Grid>
      </PageSection>
    </>
  );
};
