import { makeStyles, Theme } from '@material-ui/core';
import { commonBorder } from '../../../style/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: commonBorder,
    padding: '1rem',
  },
  title: {
    wordBreak: 'break-word',
    marginRight: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
  button: {
    minWidth: 0,
    padding: '0.5rem',
    maxHeight: '2.25rem',
  },
  icon: {
    display: 'flex',
    marginRight: '0.5rem',
    color: theme.palette.primary.main,
  },
}));
