import React from 'react';
import { KeyboardArrowDownRounded } from '@material-ui/icons';
import { Typography, MenuList, MenuItem, Popper, Paper, Grow, ClickAwayListener, Button } from '@material-ui/core';
import { useStyles } from './UserMenu.styles';
import { Link } from 'react-router-dom';
import { useLogoutHook } from '../../../customHooks/UseLogoutHook';

interface IUserMenu {
  userName: string;
}

export const UserMenu = (props: IUserMenu) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const getInitals = props.userName
    .split(' ')
    .map((n) => n[0])
    .join('.');

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const { onLogout } = useLogoutHook();

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const Initals = () => (
    <div aria-label="hidden" className={classes.initals}>
      <Typography>{getInitals}</Typography>
    </div>
  );

  return (
    <div>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        endIcon={<KeyboardArrowDownRounded />}
      >
        <Initals />
        {props.userName}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={handleClose} component={Link} to="/settings">
                    Inställningar
                  </MenuItem>
                  <MenuItem onClick={onLogout}>Logga ut</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
