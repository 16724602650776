import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.grey[200],
    zIndex: 1000000,
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '1rem',
    alignItems: 'center',
  },
  close: {
    display: 'flex',
    alignItems: 'center',
  },
}));
