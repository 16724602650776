import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { PageHeader } from '../../components/pageHeader/PageHeader';
import { PageSection } from '../../components/pageSection/PageSection';
import { Info } from '@material-ui/icons';
import { useStyles } from './AboutPage.styles';

export const AboutPage = () => {
  const classes = useStyles();
  return (
    <>
      <PageHeader title={`Information`} icon={<Info />}>
        <Typography>Här hittar du viktig information om din tjänst.</Typography>
      </PageHeader>
      <PageSection>
        <Grid container direction="column" className={classes.information}>
          <Grid item>
            <Typography variant="h2">Spärra?</Typography>
            <Typography>
              Ring <a href="tel:+4620900900">020-900 900</a> gratis dygnet runt från Sverige och{' '}
              <a href="tel:+46771800800">+46 771 800 800</a> från utlandet. Alla värdehandlingar kan spärras med ett
              enda samtal.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h2">Så fungerar tjänsten</Typography>
            <Typography>
              Läs om hur tjänsten fungerar{' '}
              <a
                href="https://www.keycode.se/sparrtjanst-sparra-dina-kort-med-ett-samtal/"
                target="_blank"
                rel="noreferrer"
              >
                här
              </a>
              .
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h2">Integritetspolicy</Typography>
            <Typography>
              Läs vår fullständiga integritetspolicy och om hur vi hanterar cookies{' '}
              <a href="https://www.keycode.se/sekretesspolicy-2/" target="_blank" rel="noreferrer">
                här
              </a>
              .
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h2">Kontakta kundtjänst</Typography>
            <Typography>
              Läs mer om hur du kontaktar oss på det sätt som passar just dig bäst{' '}
              <a href="https://www.keycode.se/kontakta-oss/" target="_blank" rel="noreferrer">
                här
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
      </PageSection>
    </>
  );
};
