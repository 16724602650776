import React from 'react';
import { useStyles } from './SideMenuFooter.styles';
import { Button } from '@material-ui/core';
import { ExitToAppRounded } from '@material-ui/icons';
import { useLogoutHook } from '../../../customHooks/UseLogoutHook';

export const SideMenuFooter = () => {
  const classes = useStyles();
  const { onLogout } = useLogoutHook();
  return (
    <Button variant="contained" startIcon={<ExitToAppRounded />} className={classes.signOutBtn} onClick={onLogout}>
      Logga ut
    </Button>
  );
};
