import React, { useContext } from 'react';
import { Route, matchPath, generatePath, useLocation, RouteProps, Redirect } from 'react-router-dom';
import { AppContext } from '../../states/AppState';
import useBreadcrumbNavigationContext, {
  IBreadcrumbItem,
} from '../breadcrumbs/breadcrumbsNavigationContext/BreadcrumbNavigationContext';

interface IProps extends RouteProps {
  breadcrumbs?: IBreadcrumbItem[];
}

export const PrivateRoute: React.FunctionComponent<IProps> = (props) => {
  const { children, ...routeProps } = props;
  const { pathname } = useLocation();
  const { breadcrumbState, setBreadcrumbState } = useBreadcrumbNavigationContext();
  const { state } = useContext(AppContext);
  React.useEffect(() => {
    if (pathname === breadcrumbState.path || !props.breadcrumbs) {
      return;
    }
    const match = matchPath(pathname, { path: props.path, exact: true, strict: false });
    const isMatch = !!match;
    if (isMatch) {
      let newBreadCrumbs: IBreadcrumbItem[] = [];
      if (props.breadcrumbs) {
        newBreadCrumbs = props.breadcrumbs.map((x) => ({
          ...x,
          to: x.to ? generatePath(x.to, match?.params) : undefined,
        }));
      }

      setBreadcrumbState({ breadcrumbNavigationItems: newBreadCrumbs, path: pathname });
    }
  }, [pathname, props.path, props.breadcrumbs, setBreadcrumbState, breadcrumbState.path]);

  return state.userDetails.name ? <Route {...routeProps}>{children}</Route> : <Redirect to="login" />;
};
