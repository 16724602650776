import { Grid, Typography, Box, InputBase, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ReturnToLogin } from '../../components/returntologin/ReturnToLogin';
import { useSetNewPassword } from '../../Fetchers';
import { useStyles } from '../login/LoginPage.styles';
import logo from '../../assets/logos/logo_black.svg';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const SetNewPasswordPage = () => {
  const classes = useStyles();
  const { code } = useParams<{ code: string }>();

  const { register, handleSubmit, errors, setError } = useForm();
  const { mutate: SetNewPassword, loading } = useSetNewPassword({});
  const [showSuccess, setShowSuccess] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = handleSubmit(async (data) => {
    if (!executeRecaptcha) {
      return setError('Verifypassword', { message: 'Tekniskt fel' });
    }

    const token = await executeRecaptcha();
    if (data.Password !== data.Verifypassword) {
      setError('Verifypassword', { message: 'Lösenordet måste matcha' });
      return;
    }

    try {
      await SetNewPassword({ resetCode: code, password: data.Password, captchaToken: token });
      setShowSuccess(true);
    } catch (error) {
      if (error.status === 400) {
        if (error?.data?.type === 'validation') {
          setError('Verifypassword', { message: error?.data?.errors.map((x: { error: string }) => x.error).join() });
        } else {
          setError('Verifypassword', { message: 'Länken är ogiltlig' });
        }
      } else if (error.status === 403) {
        setError('Verifypassword', { message: 'Länken är för gammal' });
      }
    }
  });

  return (
    <Grid className={classes.container} container justify="center" alignItems="center" component="main">
      <Grid item xl={2} lg={3} md={3} sm={5} xs={12}>
        <Box boxShadow={4} className={classes.root}>
          <img className={classes.logo} src={logo} alt="Logotyp för Keycode" />
          <Grid container justify="center">
            <Grid container item justify="flex-start">
              <Typography variant="h1" className={classes.header}>
                Byt lösenord
              </Typography>
            </Grid>
            {!showSuccess ? (
              <form className={classes.form} noValidate onSubmit={onSubmit}>
                <label className={classes.label} htmlFor="email">
                  Lösenord
                </label>
                <InputBase
                  type="password"
                  className={classes.input}
                  required
                  fullWidth
                  inputRef={register({
                    required: { value: true, message: 'Måste vara ifyllt' },
                    minLength: {
                      value: 8,
                      message: 'Minst 8 tecken',
                    },
                  })}
                  name="Password"
                  placeholder="Minst 8 tecken"
                  id="password"
                />
                {errors.Password && <Typography className={classes.error}>{errors.Password.message}</Typography>}
                <label className={classes.label} htmlFor="email">
                  Verifiera lösenord
                </label>
                <InputBase
                  className={classes.input}
                  type="password"
                  required
                  fullWidth
                  inputRef={register({
                    required: { value: true, message: 'Måste vara ifyllt' },
                    minLength: {
                      value: 8,
                      message: 'Minst 8 tecken',
                    },
                  })}
                  name="Verifypassword"
                  id="Verifypassword"
                />
                {errors.Verifypassword && (
                  <Typography className={classes.error}>{errors.Verifypassword.message}</Typography>
                )}

                <Button
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={loading}
                >
                  Spara lösenord
                </Button>
              </form>
            ) : (
              <ReturnToLogin message="Ditt nya lösenord är sparat!" />
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
