import { makeStyles, Theme } from '@material-ui/core';
import { errorMessage } from '../../components/input/Input.styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[600],
    padding: '2rem',
    [theme.breakpoints.up('sm')]: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: '100vh',
    },
  },
  container: {
    height: '100vh',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  button: {
    margin: '0 0 1rem',
  },
  logo: {
    width: '100%',
    marginBottom: '1.5rem',
  },
  input: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '0.75rem 1rem',
    width: '100%',
    marginBottom: '1rem',
  },
  pass: {
    marginBottom: '0.25rem',
  },
  label: {
    fontSize: '1.25rem',
    display: 'block',
    marginBottom: '0.25rem',
  },
  link: {
    color: 'black',
    textDecoration: 'none',
  },
  forgot: {
    marginTop: '0.25rem',
    marginBottom: '1rem',
  },
  error: {
    ...errorMessage,
  },
  submit: {
    margin: '1.5rem 0 1rem',
  },
  header: {
    marginBottom: '1rem',
  },
}));
