import React from 'react';
import { Grid, TableRow, TableHead, TableCell, TableBody, Table, TableContainer } from '@material-ui/core';
import { useStyles } from './RegisteredItemsTable.styles';
import { ConfirmRemoveDialog } from '../../../../components/confirmRemoveDialog/ConfirmRemoveDialog';
import { ProtectionObjectViewModel, useDeleteProtectionObject } from '../../../../Fetchers';

interface IRegisteredItemsTable {
  items: ProtectionObjectViewModel[];
  refetchObjects: () => void;
}

export const RegisteredItemsTable = (props: IRegisteredItemsTable) => {
  const classes = useStyles();
  const { mutate: DeleteProtectionObject } = useDeleteProtectionObject({});
  const onDelete = async (objectId: number) => {
    await DeleteProtectionObject({ objectId });
    props.refetchObjects();
  };
  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Typ</TableCell>
            <TableCell>Utfärdare</TableCell>
            <TableCell>Nummer</TableCell>
            <TableCell>Extra information</TableCell>
            <TableCell>Ägare</TableCell>
            <TableCell>
              <span className="sr-only">Knappar</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map((protectionObject: ProtectionObjectViewModel) => (
            <TableRow key={protectionObject.objectId}>
              <TableCell>
                <Grid container alignItems="center" className={classes.service}>
                  {protectionObject.cardTypeName}
                </Grid>
              </TableCell>
              <TableCell>{protectionObject.issuerName}</TableCell>
              <TableCell>{protectionObject.cardNumber}</TableCell>
              <TableCell>{protectionObject.additionalInformation}</TableCell>
              <TableCell>{protectionObject.ownerName}</TableCell>
              <TableCell className={classes.action}>
                <ConfirmRemoveDialog
                  confirmRemove={() => onDelete(protectionObject.objectId || 0)}
                  name={protectionObject.cardTypeName || ''}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
