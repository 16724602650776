import { createMuiTheme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const colorPalette = {
  grey: {
    100: '#F8F8F8',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#E8ECED',
    500: '#A7ACAD',
    600: '#CFD6DC',
    700: '#9e9e9e',
    800: '#424242',
    900: '#232D31',
  },
  primary: {
    light: '#D2E0ED',
    main: '#2A6379',
    dark: '#273C44',
    contrastText: '#fff',
  },
  secondary: {
    light: '#C6D5DB',
    main: '#A6BED4',
    dark: '#6289A5',
  },
  common: {
    white: '#fff',
    black: '#000',
  },
  error: {
    main: '#DC3030',
  },
  warning: {
    main: '#F4CA64',
    dark: '#D97923',
  },
  success: {
    main: '#39C171',
  },
};

const baseFontSize: number = 14;
export const commonBorder: string = `1px solid ${colorPalette.grey[300]}`;

export const actionInTable = {
  position: 'sticky',
  right: 0,
  background: 'white',
  textAlign: 'right',
} as CSSProperties;

const shape: number = 4;

const theme = createMuiTheme({
  palette: colorPalette,
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'initial',
      },
      containedSizeLarge: {
        padding: '0.85rem 0',
      },
      contained: {
        border: `1px solid ${colorPalette.primary.main}`,
        backgroundColor: colorPalette.common.white,
        boxShadow: `0px 2px 1px -2px rgba(42, 99, 121, 0.13), 0px 1px 3px 0px rgba(42, 99, 121, 0.14), 0px 2px 4px 0px rgba(42, 99, 121, 0.15)`,
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 600,
      },
      root: {
        borderBottom: 0,
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: shape,
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1.2rem',
        color: 'black',
        marginRight: '2rem',
        '&.Mui-focused': {
          color: colorPalette.common.black,
        },
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: 0,
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: '1rem',
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1rem',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 'auto',
        marginRight: '0.5rem',
      },
    },
    MuiListItem: {
      root: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '1.5rem 1.5rem 1rem',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 1.5rem 1rem',
        color: colorPalette.grey[900],
      },
    },
    MuiDialogActions: {
      root: {
        padding: '0.5rem 1.5rem 1rem',
      },
    },
  },
  shape: {
    borderRadius: shape,
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    htmlFontSize: baseFontSize,
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '1.1rem',
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: colorPalette.common.black,
      textTransform: 'uppercase',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '1rem',
    },
    caption: {
      fontSize: '1rem',
    },
  },
  breakpoints: {
    unit: 'rem',
    values: {
      xs: 0,
      sm: 600 / baseFontSize,
      md: 960 / baseFontSize,
      lg: 1280 / baseFontSize,
      xl: 1920 / baseFontSize,
    },
  },
  spacing: (factor) => `${0.5 * factor}rem`,
});

export default theme;
