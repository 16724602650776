import { makeStyles, Theme } from '@material-ui/core';
import { commonBorder } from '../../style/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    border: commonBorder,
    height: '100%',
  },
  content: {
    padding: '1rem',
  },
  scroll: {
    height: '20rem',
    overflowY: 'auto',
  },
}));
