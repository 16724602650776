import React from 'react';
import { useStyles } from './Form.styles';

export const Form = (props: React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>) => {
  const classes = useStyles();
  const { children, ...rest } = props;

  return (
    <form {...rest} className={classes.root}>
      {children}
    </form>
  );
};
