import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Route, RouteProps } from 'react-router-dom';

export const PublicRoute = (props: RouteProps) => {
  const { children, ...routeProps } = props;
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
      scriptProps={{ async: true, defer: true, appendTo: 'body' }}
    >
      <Route {...routeProps} />
    </GoogleReCaptchaProvider>
  );
};
