import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { DatePicker } from '../../../../components/datepicker/DatePicker';
import { Input } from '../../../../components/input/Input';
import { ValidationEnum } from '../../../../helpers/ValidationEnum';

export interface IValidationComponent {
  validation?: ValidationEnum;
  register: any;
  errors: FieldErrors<Record<string, any>>;
  control: Control<Record<string, any>>;
}

export const ValidationComponent = (props: IValidationComponent) => {
  switch (props.validation) {
    case ValidationEnum.LastValidDate:
      return (
        <Controller
          name="lastValidDate"
          defaultValue={false}
          control={props.control}
          rules={{ required: true }}
          render={(props: any) => (
            <DatePicker label="Sista giltlighetsdatum" value={props.value} onChange={props.onChange} />
          )}
        />
      );
    case ValidationEnum.FourDigits:
      return (
        <Input
          name="cardNumber"
          label="Fyra sista siffrorna"
          type="number"
          register={props.register}
          customError={props.errors['cardNumber']}
          registerRules={{
            required: 'Måste vara ifyllt',
            pattern: {
              value: /^[\d]{4}$/,
              message: 'Måste vara fyra siffror',
            },
          }}
          placeholder="1234"
          helperText="(på framsidan av kortet)"
        />
      );
    case ValidationEnum.SwedishCellphoneNumber:
      return (
        <Input
          name="cardNumber"
          label="Mobiltelefonnummer"
          type="number"
          register={props.register}
          customError={props.errors['cardNumber']}
          registerRules={{
            required: 'Måste vara ifyllt',
            pattern: {
              value: /^07[\d]{7,9}$/,
              message: 'Måste följa formatet 07XXXXXXXX',
            },
          }}
          placeholder="07XXXXXXXX"
        />
      );
    case ValidationEnum.ImeiNumber:
      return (
        <>
          <Input
            name="cardNumber"
            label="IMEInummer"
            type="number"
            register={props.register}
            customError={props.errors['cardNumber']}
            placeholder="15-17 siffror"
            registerRules={{
              required: 'Måste vara ifyllt',
              pattern: {
                value: /^[\d]{15,17}$/,
                message: 'Måste vara mellan 15-17 siffror',
              },
            }}
          />
          <Input
            name="additionalInfo"
            label="Mobiltelefonnummer"
            type="number"
            register={props.register}
            customError={props.errors['additionalInfo']}
            registerRules={{
              pattern: {
                value: /^07[\d]{7,9}$/,
                message: 'Måste följa formatet 07XXXXXXXX',
              },
            }}
            placeholder="07XXXXXXXX"
          />
        </>
      );
    case ValidationEnum.EightDigits:
      return (
        <Input
          name="cardNumber"
          label="Digipassnummer"
          type="number"
          register={props.register}
          customError={props.errors['cardNumber']}
          registerRules={{
            required: 'Måste vara ifyllt',
            pattern: {
              value: /^[\d]{8}$/,
              message: 'Måste vara åtta siffror',
            },
          }}
          placeholder="12345678"
        />
      );
    case ValidationEnum.OnlyDigits:
      return (
        <Input
          name="cardNumber"
          label="Kortnummer"
          type="number"
          register={props.register}
          customError={props.errors['cardNumber']}
          registerRules={{
            required: 'Måste vara ifyllt',
            pattern: {
              value: /^[\d]+$/,
              message: 'Måste vara siffror',
            },
          }}
          placeholder="12345678"
        />
      );
    case ValidationEnum.IdentificationOrOrganizationNumber:
      return (
        <Input
          name="cardNumber"
          label="Person/Organisationsnummer"
          type="number"
          register={props.register}
          customError={props.errors['cardNumber']}
          registerRules={{
            required: 'Måste vara ifyllt',
            pattern: {
              value: /^[\d]{10,12}$/,
              message: 'Måste vara mellan tio till tolv siffor',
            },
          }}
          placeholder="12345678"
        />
      );
    case ValidationEnum.NoValidation:
    default:
      return null;
  }
};
