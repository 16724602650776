import { makeStyles, Theme } from '@material-ui/core';
import { actionInTable, commonBorder } from '../../../../style/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    overflowX: 'auto',
    border: commonBorder,
  },
  head: {
    borderBottom: commonBorder,
  },
  title: {
    padding: '0.75rem 0',
    fontWeight: 700,
  },
  action: {
    ...actionInTable,
  },
  number: {
    fontWeight: 300,
  },
  bold: {
    fontWeight: 700,
  },
  availableSpaces: {
    color: theme.palette.success.dark,
  },
  icon: {
    display: 'flex',
    marginRight: '1rem',
    color: theme.palette.primary.main,
    '&  > svg': {
      fontSize: '2.5rem',
    },
  },
  iconButton: {
    minWidth: 0,
    padding: '0.5rem',
    maxHeight: '2.25rem',
  },
}));
