import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Grid, Typography, FormControlLabel, Radio } from '@material-ui/core';
import { PageHeader } from '../../../../components/pageHeader/PageHeader';
import { PageSection } from '../../../../components/pageSection/PageSection';
import { Card } from '../../../../components/card/Card';
import { RegisteredItemsTable } from '../../components/registeredItemsTable/RegisteredItemsTable';
import { useHistory, useParams } from 'react-router-dom';
import Select, { ISelectOption } from '../../../../components/select/Select';
import { RadioGroup } from '../../../../components/radioGroup/RadioGroup';
import { Form } from '../../../../components/form/Form';
import {
  useGetAllCardTypesByCategoryGroupAndIssuer,
  useGetAllIssuersByCategoryGroup,
  useGetAllProtectionObjectsByCategoryGroup,
  useGetCard,
  useCreateProtectionObject,
  GetCardResult,
} from '../../../../Fetchers';
import { AppContext } from '../../../../states/AppState';
import { getIconByCategoryGroupId, getIngressByCategoryGroupId } from '../../../../helpers/CardCategoryGroup';
import { ValidationComponent } from './ValidationComponent';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { Input } from '../../../../components/input/Input';

export const RegisterItemPage = () => {
  const { id: idString } = useParams<{ id: string }>();
  const id = Number.parseInt(idString);
  const { state } = useContext(AppContext);
  const history = useHistory();
  const { handleSubmit, register, errors, control, clearErrors, reset } = useForm();

  const { data: protectionObjects, refetch: refetchProtectionObjects } = useGetAllProtectionObjectsByCategoryGroup({
    groupId: id,
  });
  const { data: issuers } = useGetAllIssuersByCategoryGroup({ groupId: id });
  const [selectedIssuer, setSelectedIssuer] = useState<ISelectOption | null>(null);
  const [selectedCardType, setSelectedCardType] = useState<ISelectOption | null>(null);
  const [otherOwner, setOtherOwner] = useState<'no' | 'yes'>('no');
  const [card, setCard] = useState<GetCardResult | null>(null);

  const { data: cardTypes, refetch: getCardTypes } = useGetAllCardTypesByCategoryGroupAndIssuer({
    lazy: true,
    groupId: 0,
    issuerId: 0,
  });

  const { data: cardData, refetch: getCard } = useGetCard({
    lazy: true,
    cardTypeId: 0,
    issuerId: 0,
  });

  useEffect(() => {
    setCard(cardData);
  }, [cardData]);

  const { mutate: createProtectionObject } = useCreateProtectionObject({});

  const onSubmit = async (submittedData: any) => {
    if (!card?.cardResult?.id) {
      return;
    }
    try {
      let cardNumber = submittedData.cardNumber;
      if (submittedData.lastValidDate) {
        cardNumber = dayjs(submittedData.lastValidDate).format('YYYY-MM-DD');
      }
      await createProtectionObject({
        cardId: card.cardResult.id,
        cardNumber: cardNumber,
        additionalInformation: submittedData.additionalInformation,
        groupId: id,
        otherOwnerIdentification: submittedData.otherOwnerIdentification,
        otherOwnerName: submittedData.otherOwnerName,
      });
      await refetchProtectionObjects({ pathParams: { groupId: id } });
      setSelectedIssuer(null);
      setSelectedCardType(null);
      setOtherOwner('no');
      setCard(null);
      reset();
    } catch (error) {}
  };
  const onIssuerChange = useCallback(
    (option) => {
      getCardTypes({ pathParams: { groupId: id, issuerId: Number.parseInt(option.value) } });
      setSelectedCardType(null);
      setSelectedIssuer(option);
    },
    [getCardTypes, id],
  );

  const onCardTypeChange = useCallback(
    (option) => {
      if (!selectedIssuer) {
        return;
      }
      getCard({
        pathParams: { cardTypeId: Number.parseInt(option.value), issuerId: Number.parseInt(selectedIssuer.value) },
      });
      setSelectedCardType(option);
    },
    [getCard, selectedIssuer],
  );

  const categoryGroup = state.categoryGroups.find((group) => group.id === id);
  if (!categoryGroup) {
    return null;
  }
  const ingress = getIngressByCategoryGroupId(id);

  const isMax = Boolean(
    categoryGroup.maxCount && categoryGroup.maxCount <= (protectionObjects?.groupResult?.[0]?.cards?.length || 0),
  );

  const getSpacesLeft = () => {
    const length = protectionObjects?.groupResult?.[0]?.cards?.length ?? 0;
    if (categoryGroup.maxCount) {
      return `${length} / ${categoryGroup.maxCount}`;
    } else {
      return `${length}`;
    }
  };

  return (
    <>
      <PageHeader title={categoryGroup.name || ''} icon={getIconByCategoryGroupId(categoryGroup.id || 0)}>
        <Typography>{ingress.MainIngress}</Typography>
        <Typography>{ingress.SubIngress}</Typography>
      </PageHeader>
      <PageSection>
        <Grid container spacing={3}>
          <Grid lg={6} xs={12} item>
            <Card
              header={{
                title: 'Registrera nytt kort',
              }}
              disableMaxHeight
            >
              <Grid container alignItems="center" justify="center">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Select
                    selectProps={{
                      value: selectedIssuer,
                      onChange: onIssuerChange,
                      options:
                        issuers?.idNameList?.map((x) => ({ value: x.id?.toString() || '', label: x.name || '' })) || [],
                    }}
                    label="Utställare"
                  ></Select>
                  <Grid item container justify="flex-end">
                    <Select
                      label="Korttyp"
                      selectProps={{
                        value: selectedCardType,
                        onChange: onCardTypeChange,
                        options:
                          (selectedIssuer &&
                            cardTypes?.idNameList?.map((x) => ({
                              value: x.id?.toString() || '',
                              label: x.name || '',
                            }))) ||
                          [],
                      }}
                    ></Select>
                  </Grid>
                  <RadioGroup
                    label="Annan ägare"
                    value={otherOwner}
                    onChange={(value) => {
                      clearErrors();
                      setOtherOwner(value as 'yes' | 'no');
                    }}
                  >
                    <FormControlLabel value="no" control={<Radio color="primary" />} label="Nej" />
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Ja" />
                  </RadioGroup>
                  {otherOwner === 'yes' && (
                    <>
                      <Input
                        name="otherOwnerName"
                        label="Namn på ägare"
                        register={register}
                        customError={errors['otherOwnerName']}
                        registerRules={{}}
                      />
                      <Input
                        name="otherOwnerIdentification"
                        label="Person/Organisationsnummer på ägare"
                        type="number"
                        register={register}
                        customError={errors['otherOwnerIdentification']}
                        placeholder="10-12 siffror"
                        registerRules={{
                          pattern: {
                            value: /^[\d]{10,12}$/,
                            message: 'Måste vara mellan tio till tolv siffor',
                          },
                        }}
                      />
                    </>
                  )}
                  <ValidationComponent
                    validation={card?.cardResult?.validation}
                    register={register}
                    errors={errors}
                    control={control}
                  />
                  <Grid item container justify="flex-end" alignItems="flex-start">
                    <Button variant="contained" onClick={() => history.goBack()}>
                      Avbryt
                    </Button>
                    <Button variant="contained" color="primary" type="submit" disabled={!card || isMax}>
                      Registrera
                    </Button>
                  </Grid>
                  {isMax && (
                    <Typography variant="h2" color="error">
                      Max antal kort registrerade
                    </Typography>
                  )}
                  {card && (
                    <Typography variant="h3">{card.cardResult?.canBeCancelled ? 'Spärrbar' : 'Ej Spärrbar'}</Typography>
                  )}
                </Form>
              </Grid>
            </Card>
          </Grid>

          <Grid lg={6} xs={12} item>
            <Card
              header={{
                title: `Registrerade ${categoryGroup.name}`,
                icon: getIconByCategoryGroupId(categoryGroup.id || 0),
                spacesLeft: getSpacesLeft(),
              }}
            >
              <RegisteredItemsTable
                items={protectionObjects?.groupResult?.[0]?.cards || []}
                refetchObjects={refetchProtectionObjects}
              />
            </Card>
          </Grid>
        </Grid>
      </PageSection>
    </>
  );
};
