import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';
import { Box, InputBase, Typography } from '@material-ui/core';
import { AppContext } from '../../states/AppState';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/logos/logo_black.svg';
import { useStyles } from './LoginPage.styles';
import { useActivationCodeLogin } from '../../Fetchers';
import { CookieBanner } from '../../components/cookieBanner/CookieBanner';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const ActivationCodeLoginPage = () => {
  const classes = useStyles();
  const {
    dispatch,
    state: { seenCookieBanner },
  } = useContext(AppContext);
  const { register, handleSubmit, errors, setError } = useForm();
  const { mutate: login, loading } = useActivationCodeLogin({});
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (!executeRecaptcha) {
        return setError('ActivationCode', { message: 'Tekniskt fel' });
      }
      const token = await executeRecaptcha();

      const result = await login({
        activationCode: data.ActivationCode,
        identificationNumber: data.IdentificationNumber,
        captchaToken: token,
      });
      if (result.loggedInUser?.name) {
        dispatch({ type: 'UPDATE_USER_DETAILS', payload: { name: result.loggedInUser.name } });
        localStorage.setItem('authenticatedName', result.loggedInUser?.name);
      } else {
        throw new Error();
      }
    } catch (error) {
      return setError('ActivationCode', { message: 'Fel Identifikation/aktiveringskod' });
    }
    history.replace('/settings');
  });

  return (
    <Grid className={classes.container} container justify="center" alignItems="center" component="main">
      <Typography variant="h1" className="sr-only">
        Logga in med Aktiveringskod
      </Typography>
      <Grid item xl={2} lg={3} md={3} sm={5} xs={12}>
        <Box boxShadow={4} className={classes.root}>
          <Grid container justify="center">
            <img className={classes.logo} src={logo} alt="Logotyp för Keycode" />

            <form className={classes.form} noValidate onSubmit={onSubmit}>
              <label className={classes.label} htmlFor="identification-number">
                Personnummer
              </label>
              <InputBase
                inputRef={register}
                required
                fullWidth
                className={classes.input}
                id="identification-number"
                placeholder="ÅÅÅÅMMDDXXXX"
                name="IdentificationNumber"
                autoComplete="identificationNumber"
                autoFocus
              />
              <label className={classes.label} htmlFor="ActivationCode">
                Aktiveringskod
              </label>
              <InputBase
                className={classes.input}
                required
                fullWidth
                inputRef={register}
                name="ActivationCode"
                id="ActivationCode"
              />
              {errors.ActivationCode && (
                <Typography className={classes.error}>{errors.ActivationCode.message}</Typography>
              )}
              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={loading}
              >
                Logga in
              </Button>
            </form>
          </Grid>
        </Box>
      </Grid>
      {seenCookieBanner !== 'true' && (
        <CookieBanner onClose={() => dispatch({ type: 'UPDATE_SEEN_COOKIE_BANNER', payload: 'true' })} />
      )}
    </Grid>
  );
};
