import { makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const commonLinkStyles = {
  display: 'flex',
  alignItems: 'center',
  padding: '0.5rem 1rem',
  width: '100%',
  textDecoration: 'none',
} as CSSProperties;

export const useStyles = makeStyles((theme: Theme) => ({
  label: {
    textTransform: 'uppercase',
    marginLeft: '1rem',
    marginTop: '1rem',
    marginBottom: '0.75rem',
    display: 'flex',
    fontWeight: 600,
  },
  icon: {
    color: theme.palette.primary.light,
  },
  link: {
    ...commonLinkStyles,
    opacity: 0.8,
    color: theme.palette.background.paper,
  },
  activeLink: {
    ...commonLinkStyles,
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.grey[900],
  },
  listItem: {
    padding: 0,
  },
  menuContainer: {
    flexGrow: 1,
  },
}));
