import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-end',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& > *': {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      '&:not(fieldset)': {
        marginBottom: '1.5rem',
      },
      marginBottom: '0.5rem',
    },

    [theme.breakpoints.up('md')]: {
      '& > *': {
        marginBottom: '1.5rem',
      },
    },
    '& button': {
      marginLeft: '1rem',
    },
  },
}));
