import React, { useReducer } from 'react';
import './App.css';
import { BreadcrumbNavigationContextProvider } from './components/breadcrumbs/breadcrumbsNavigationContext/BreadcrumbNavigationContext';
import { Routes } from './components/routes/Routes';
import { AppContext, AppReducer, InitialAppState } from './states/AppState';
import { RestfulProvider } from 'restful-react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtil from '@date-io/dayjs';
import 'dayjs/locale/sv';
import dayjs from 'dayjs';

function App() {
  dayjs.locale('sv');
  const [state, dispatch] = useReducer(AppReducer, InitialAppState);
  function onError():
    | ((
        err: { message: string; data: unknown; status?: number | undefined },
        retry: () => Promise<unknown>,
        response?: Response | undefined,
      ) => void)
    | undefined {
    return (error) => {
      if (
        (!error.status || error.status === 401) &&
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/activationcodelogin'
      ) {
        localStorage.removeItem('authenticatedName');
        window.location.href = '/login';
      }
    };
  }
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <RestfulProvider
        base={process.env.REACT_APP_API_URL || ''}
        requestOptions={() => ({ credentials: 'include' })}
        onError={onError()}
      >
        <MuiPickersUtilsProvider utils={DayJsUtil}>
          <BreadcrumbNavigationContextProvider>
            <Routes />
          </BreadcrumbNavigationContextProvider>
        </MuiPickersUtilsProvider>
      </RestfulProvider>
    </AppContext.Provider>
  );
}

export default App;
