export enum ValidationEnum {
  NoValidation = 0,
  LastValidDate = 1,
  FourDigits = 2,
  SwedishCellphoneNumber = 4,
  ImeiNumber = 8,
  EightDigits = 16,
  OnlyDigits = 32,
  IdentificationOrOrganizationNumber = 64,
}
