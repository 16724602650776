import React from 'react';
import ReactSelect, { Props } from 'react-select';
import AsyncSelect, { Props as AsyncProps } from 'react-select/async';
import { Label } from '../label/Label';
import { styles, useStyles } from './Select.styles';

export interface ISelectOption {
  value: string;
  label: string;
}

interface ISelectProps {
  selectProps?: Props<ISelectOption> | AsyncProps<ISelectOption>;
  autoComplete?: boolean;
  label: string;
  helperText?: string;
  placeholder?: string;
}

function Select(props: ISelectProps) {
  const labelWithoutSpaces = props.label?.replace(/ /g, '');
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Label label={props.label} helperText={props.helperText} />
      {props.autoComplete ? (
        <AsyncSelect
          cacheOptions
          defaultOptions
          styles={styles}
          {...(props.selectProps as AsyncProps<ISelectOption>)}
          aria-labelledby={labelWithoutSpaces}
          placeholder={props.placeholder ?? 'Välj...'}
          noOptionsMessage={() => 'Inga alternativ'}
        />
      ) : (
        <ReactSelect
          styles={styles}
          {...props.selectProps}
          placeholder={props.placeholder ?? 'Välj...'}
          noOptionsMessage={() => 'Inga alternativ'}
          aria-labelledby={labelWithoutSpaces}
        />
      )}
    </div>
  );
}

export default Select;
