import React, { useContext } from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { PageHeader } from '../../../components/pageHeader/PageHeader';
import { PageSection } from '../../../components/pageSection/PageSection';
import { BlockRounded } from '@material-ui/icons';
import { Card } from '../../../components/card/Card';
import { ServiceTable } from '../components/serviceTable/ServiceTable';
import { RegisteredItemsTable } from '../components/registeredItemsTable/RegisteredItemsTable';

import { useGetAllProtectionObjects } from '../../../Fetchers';
import { getIconByCategoryGroupId } from '../../../helpers/CardCategoryGroup';
import { AppContext } from '../../../states/AppState';

export const BlockservicePage = () => {
  const { data, refetch } = useGetAllProtectionObjects({});
  const { state } = useContext(AppContext);
  // if (loading || !data || !data.groupResult) return null;

  return (
    <>
      <PageHeader title="Spärrtjänst" icon={<BlockRounded />}>
        <Typography>
          Här registrerar du dina mobiler, mobila bredband, konto- och id-kort sam banksäkerhetsdosor.
        </Typography>
        <Typography>Det kan ta upp till två arbetsdagar innan uppgifterna registreras som spärrbara.</Typography>
        <Typography>
          Saknar du utställare/värdehandling i listorna du önskar registrera ber vi er vänligen att mejla in till oss på{' '}
          <Link href="mailto:info@keycode.se">info@keycode.se</Link>.
        </Typography>
      </PageHeader>
      <PageSection>
        <Grid container spacing={3}>
          <Grid xs={12} item>
            <ServiceTable
              rows={state.categoryGroups.map((categoryGroup) => {
                const matchingGroupResult = data?.groupResult?.find((x) => x.groupId === categoryGroup.id);
                return {
                  icon: getIconByCategoryGroupId(categoryGroup.id || 0),
                  name: categoryGroup.name || '',
                  spacesLeft: categoryGroup.maxCount
                    ? categoryGroup.maxCount - (matchingGroupResult?.cards?.length || 0)
                    : undefined,
                  registered: matchingGroupResult?.cards?.length || 0,
                  link: `${categoryGroup.id}`,
                };
              })}
            />
          </Grid>
          {state.categoryGroups.map((categoryGroup) => {
            const matchingGroupResult = data?.groupResult?.find((x) => x.groupId === categoryGroup.id);
            const spacesLeft = matchingGroupResult?.cards?.length || 0;
            return (
              <Grid lg={6} xs={12} item key={categoryGroup.id}>
                <Card
                  header={{
                    title: `Registrera ${categoryGroup.name}`,
                    spacesLeft: categoryGroup.maxCount
                      ? `${spacesLeft} / ${categoryGroup.maxCount}`
                      : spacesLeft.toString(),
                    icon: getIconByCategoryGroupId(categoryGroup.id || 0),
                    to: `/blockservice/registrer/${categoryGroup.id}`,
                  }}
                >
                  <RegisteredItemsTable items={matchingGroupResult?.cards || []} refetchObjects={refetch} />
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </PageSection>
    </>
  );
};
