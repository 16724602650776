import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CreditCard = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <g id="Group_4" data-name="Group 4" transform="translate(-818 -452)">
      <rect width="24" height="24" transform="translate(818 452)" fill="none" />
      <path
        d="M17.982,26.911H3V25.423H17.982Zm5.994-8.923V31.372a1.5,1.5,0,0,1-1.5,1.487H1.5A1.5,1.5,0,0,1,0,31.372V17.987A1.5,1.5,0,0,1,1.5,16.5H22.478A1.5,1.5,0,0,1,23.976,17.987Zm-1.5,4.462H1.5v8.923H22.478Zm0-4.462H1.5v1.487H22.478ZM8.991,28.4H3v1.487H8.991Z"
        transform="translate(818 439.028)"
        fillRule="evenodd"
      />
    </g>
  </SvgIcon>
);
