import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import React, { useContext } from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { DashboardPage } from '../../pages/dashboard/DashboardPage';
import { PrivateRoute } from './PrivateRoute';
import { Main } from '../main/Main';
import theme from '../../style/theme';
import { BlockserviceRoute } from '../../pages/blockservice/BlockserviceRoute';
import { SettingsPage } from '../../pages/settings/SettingsPage';
import { LoginPage } from '../../pages/login/LoginPage';
import { AppContext } from '../../states/AppState';
import { RequestPasswordEmailPage } from '../../pages/passwordreset/RequestPasswordEmailPage';
import { SetNewPasswordPage } from '../../pages/passwordreset/SetNewPasswordPage';
import { RegisterUserPage } from '../../pages/registeruser/RegisterUser';
import { AboutPage } from '../../pages/about/AboutPage';
import { ActivationCodeLoginPage } from '../../pages/login/ActivationCodeLoginPage';
import { PublicRoute } from './PublicRoute';

export const Routes = () => {
  const { state } = useContext(AppContext);
  const NotFound = () => <p>Sorry, nothing here</p>;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Switch>
          <Redirect exact path="/" to={state.userDetails.name ? '/dashboard' : '/login'} />
          <PublicRoute component={LoginPage} path="/login" />
          <PublicRoute component={ActivationCodeLoginPage} path="/activationcodelogin" exact />
          <PublicRoute component={RequestPasswordEmailPage} path="/forgotpassword" />
          <PublicRoute component={SetNewPasswordPage} path="/resetpassword/:code" />
          <PublicRoute component={RegisterUserPage} path="/registeruser" />
          <Main>
            <Switch>
              <PrivateRoute
                breadcrumbs={[
                  { title: 'Välkommen' },
                  {
                    title: 'Startsida',
                  },
                ]}
                component={DashboardPage}
                path="/dashboard"
                exact
              />
              <PrivateRoute component={BlockserviceRoute} path="/blockservice" exact={false} />
              <PrivateRoute
                component={SettingsPage}
                path="/settings"
                breadcrumbs={[{ title: 'Inställningar' }, { title: 'Mitt konto' }]}
                exact
              />
              <PrivateRoute
                breadcrumbs={[
                  { title: 'Välkommen' },
                  {
                    title: 'Information',
                  },
                ]}
                component={AboutPage}
                path="/about"
                exact
              />
              <Route component={NotFound} path="*" />
            </Switch>
          </Main>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};
