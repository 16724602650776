import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  initals: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50px',
    padding: '0.3rem',
    marginRight: '0.5rem',
    '& > *': {
      fontWeight: 700,
      fontSize: '0.8rem',
    },
  },
}));
