import { makeStyles, Theme } from '@material-ui/core';
import { actionInTable } from '../../../../style/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  table: {
    margin: '0 -1rem',
    width: 'calc(100% + 1rem)',
    '& td': {
      paddingTop: '0',
      paddingBottom: '0',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '0.5rem',
      },
    },
  },
  service: {
    '& > *': {
      marginRight: '0.5rem',
    },
  },
  action: {
    paddingRight: 0,
    ...actionInTable,
  },
}));
