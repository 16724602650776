import React from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { AddRounded } from '@material-ui/icons';
import { useStyles } from './CardHeader.styles';

export interface ICardHeader {
  icon?: React.ReactNode;
  title?: string;
  to?: string;
  spacesLeft?: string;
  disabled?: boolean;
}

export const CardHeader = (props: ICardHeader) => {
  const classes = useStyles();
  return (
    <header className={classes.root}>
      <Grid container wrap="nowrap" alignItems="center">
        {props.icon && <div className={classes.icon}>{props.icon}</div>}
        {props.title && (
          <Typography component="h2" variant="h3" className={classes.title}>
            {props.title} {props.spacesLeft && `(${props.spacesLeft})`}
          </Typography>
        )}
      </Grid>
      {props.to && (
        <Button
          disabled={props.disabled}
          component={Link}
          to={props.to}
          className={classes.button}
          color="primary"
          variant="contained"
        >
          <AddRounded />
        </Button>
      )}
    </header>
  );
};
