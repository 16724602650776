import { KeyboardDatePicker } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React from 'react';
import { Label } from '../label/Label';
import { useStyles } from './Datepicker.styles';

export interface ISelectOption {
  value: string;
  label: string;
}

interface IDatePickerProps {
  value?: ParsableDate;
  label: string;
  helperText?: string;
  onChange: (date: MaterialUiPickersDate) => void;
}

export const DatePicker = (props: IDatePickerProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Label label={props.label} helperText={props.helperText} />
      <KeyboardDatePicker
        cancelLabel="Avbryt"
        clearLabel="Rensa"
        value={props.value || null}
        clearable
        name="picker"
        mask="____-__-__"
        onChange={(date) => props.onChange(date)}
        format="YYYY-MM-DD"
      />
    </div>
  );
};
