import React, { useContext } from 'react';
import { AppBar, Grid, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import { Toolbar as MuiToolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { UserMenu } from '../userMenu/UserMenu';
import { useStyles } from './Toolbar.styles';
import { Breadcrumbs } from '../../breadcrumbs/Breadcrumbs';
import { AppContext } from '../../../states/AppState';

interface IToolbar {
  openMenu: () => void;
}

export const Toolbar = (props: IToolbar) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { state } = useContext(AppContext);
  const { name } = state.userDetails;

  return (
    <AppBar color="inherit" position="fixed" className={classes.appBar}>
      <MuiToolbar className={classes.toolbar}>
        <Grid container>
          <Grid item container justify="space-between" alignItems="center">
            <IconButton
              color="inherit"
              aria-label="Öppna meny"
              edge="start"
              onClick={props.openMenu}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            {!mobileView && <Breadcrumbs />}
            <UserMenu userName={name || ''} />
          </Grid>
        </Grid>
        {mobileView && (
          <Grid container>
            <Breadcrumbs />
          </Grid>
        )}
      </MuiToolbar>
    </AppBar>
  );
};
