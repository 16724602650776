import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { Card } from '../../components/card/Card';
import { PageSection } from '../../components/pageSection/PageSection';
import { PageHeader } from '../../components/pageHeader/PageHeader';
import { Input } from '../../components/input/Input';
import { SettingsRounded } from '@material-ui/icons';
import { Form } from '../../components/form/Form';
import { AppContext } from '../../states/AppState';
import { useForm } from 'react-hook-form';
import { useUpdateUser } from '../../Fetchers';
import { useGetUserDetails } from '../../customHooks/UseGetUserDetails';

export const SettingsPage = () => {
  const { state } = useContext(AppContext);
  const { register, handleSubmit, errors, setValue, setError } = useForm();
  const { refetch: refetchUserDetails } = useGetUserDetails({ lazy: true });
  const { mutate: UpdateUser } = useUpdateUser({});
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    setValue('Email', state.userDetails.email);
    setValue('Name', state.userDetails.name);
    setValue('PhoneNumber', state.userDetails.phoneNumber);
  }, [state.userDetails, setValue]);

  const onSubmit = handleSubmit(async (data: any) => {
    if (data.Password !== data.Verifypassword) {
      setError('Verifypassword', { message: 'Lösenordet måste matcha' });
      return;
    }
    try {
      await UpdateUser(data);
      refetchUserDetails();
      setShowSuccess(true);
    } catch (error) {
      if (error.data.type === 'validation') {
        error.data.errors.forEach((er: any) => {
          setError(er.name, { message: er.error });
        });
      } else {
        setError('Verifypassword', { message: 'Tekniskt fel' });
      }
    }
  });

  return (
    <>
      <PageHeader title="Inställningar" icon={<SettingsRounded />}></PageHeader>
      <PageSection>
        <Grid container spacing={3}>
          <Grid lg={6} xs={12} item>
            <Card
              header={{
                title: 'Ditt konto',
              }}
              disableMaxHeight
            >
              <Grid container alignItems="center" justify="center">
                <Form noValidate onSubmit={onSubmit}>
                  <Input
                    label="Namn"
                    name="Name"
                    register={register}
                    registerRules={{
                      required: 'Måste vara ifyllt',
                    }}
                    customError={errors.Name}
                  />
                  <Input
                    label="E-mail"
                    name="Email"
                    register={register}
                    registerRules={{
                      pattern: { value: /^\S+@\S+\.\S+$/, message: 'Måste vara en giltlig epost' },
                      required: 'Måste vara ifyllt',
                    }}
                    customError={errors.Email}
                  />
                  <Input label="Telefonnummer" register={register} name="PhoneNumber" />
                  <Input
                    label="Nytt lösenord"
                    type="password"
                    name="Password"
                    register={register}
                    registerRules={{
                      minLength: {
                        value: 8,
                        message: 'Minst 8 tecken',
                      },
                    }}
                    customError={errors.Password}
                  />
                  <Input
                    label="Bekräfta nytt lösenord"
                    type="password"
                    name="Verifypassword"
                    register={register}
                    registerRules={{
                      minLength: {
                        value: 8,
                        message: 'Minst 8 tecken',
                      },
                    }}
                    customError={errors.Verifypassword}
                  />

                  <Grid item container justify="flex-end" alignItems="flex-start">
                    <Button variant="contained" color="primary" type="submit">
                      Spara
                    </Button>
                  </Grid>
                  {showSuccess && <Typography variant="h2">Sparat!</Typography>}
                </Form>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </PageSection>
    </>
  );
};
