import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Drawer, CssBaseline, useMediaQuery } from '@material-ui/core';
import { Sidemenu } from '../sidemenu/Sidemenu';
import { Toolbar } from '../navigation/toolbar/Toolbar';
import { useStyles } from './Main.styles';
import { useGetUserDetails } from '../../customHooks/UseGetUserDetails';

export const Main: React.FC = (props) => {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  useGetUserDetails({});
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Toolbar openMenu={handleDrawerToggle} />
      <nav className={classes.drawer} aria-label="Huvudnavigering">
        {mobileView ? (
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Sidemenu closeMenu={handleDrawerToggle} />
          </Drawer>
        ) : (
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <Sidemenu />
          </Drawer>
        )}
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
};
