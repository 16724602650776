import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  radioGroup: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '20rem',
    },
  },
}));
