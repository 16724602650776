import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Tag = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <g id="Group_14" data-name="Group 14" transform="translate(-929 -454)">
      <path
        id="bi_tag-fill"
        d="M9.659,8.25a1.352,1.352,0,0,0-.952.391,1.331,1.331,0,0,0-.394.945v6.129a1.331,1.331,0,0,0,.395.945l9.426,9.355a1.354,1.354,0,0,0,1.9,0l6.175-6.129a1.33,1.33,0,0,0,0-1.89L16.786,8.642a1.352,1.352,0,0,0-.952-.392Zm5.386,4.678a2,2,0,0,1-.592,1.417,2.031,2.031,0,0,1-2.856,0,1.994,1.994,0,0,1,0-2.835,2.031,2.031,0,0,1,2.856,0,2,2,0,0,1,.592,1.417Z"
        transform="translate(923.541 448.597)"
        fillRule="evenodd"
      />
      <g id="Group_13" data-name="Group 13" transform="translate(25)">
        <g id="Group_11" data-name="Group 11" transform="translate(0 -1)">
          <g id="Group_10" data-name="Group 10" transform="translate(31)">
            <g id="Group_8" data-name="Group 8" transform="translate(3 3)">
              <g id="Group_5" data-name="Group 5" transform="translate(52)">
                <rect
                  id="Rectangle_2"
                  data-name="Rectangle 2"
                  width="24"
                  height="24"
                  transform="translate(818 452)"
                  fill="none"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
