import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Calculator = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <g transform="translate(-904 -454)">
      <g transform="translate(0 -1)">
        <g transform="translate(889.925 448)">
          <path d="M33.658,9.787V28.229H18.174V9.787Zm0-1.537H18.174a1.554,1.554,0,0,0-1.095.45,1.531,1.531,0,0,0-.454,1.087V28.229a1.531,1.531,0,0,0,.454,1.087,1.555,1.555,0,0,0,1.095.45H33.658a1.555,1.555,0,0,0,1.095-.45,1.531,1.531,0,0,0,.454-1.087V9.787A1.531,1.531,0,0,0,34.753,8.7,1.554,1.554,0,0,0,33.658,8.25Z" />
          <path d="M37.406,94.875h1.549v1.537H37.406Z" transform="translate(-16.91 -70.488)" />
          <path d="M87.281,94.875H88.83v1.537H87.281Z" transform="translate(-57.494 -70.488)" />
          <path d="M37.406,74.25h1.549v1.537H37.406Z" transform="translate(-16.91 -53.705)" />
          <path d="M87.281,74.25H88.83v1.537H87.281Z" transform="translate(-57.494 -53.705)" />
          <path d="M37.406,53.625h1.549v1.537H37.406Z" transform="translate(-16.91 -36.922)" />
          <path d="M62.344,94.875h1.548v1.537H62.344Z" transform="translate(-37.202 -70.488)" />
          <path d="M62.344,74.25h1.548v1.537H62.344Z" transform="translate(-37.202 -53.705)" />
          <path d="M62.344,53.625h1.548v1.537H62.344Z" transform="translate(-37.202 -36.922)" />
          <path d="M87.281,53.625H88.83v1.537H87.281Z" transform="translate(-57.494 -36.922)" />
          <path d="M37.406,28.875h10.84V31.18H37.406Z" transform="translate(-16.91 -16.783)" />
        </g>
        <g id="Group_10" data-name="Group 10" transform="translate(31)">
          <g id="Group_8" data-name="Group 8" transform="translate(3 3)">
            <g id="Group_5" data-name="Group 5" transform="translate(52)">
              <rect width="24" height="24" transform="translate(818 452)" fill="none" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
