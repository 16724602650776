import React from 'react';
import { FormControl, InputBase, InputProps, Typography } from '@material-ui/core';
import { Label } from '../label/Label';
import { useStyles } from './Input.styles';
import { FieldError, ValidationRules } from 'react-hook-form';

export interface IInputProps extends InputProps {
  label: string;
  helperText?: string;
  viewOnlyValue?: string;
  register?: any;
  registerRules?: ValidationRules;
  customError?: FieldError | undefined;
}

export const Input = (props: IInputProps) => {
  const classes = useStyles();
  const labelWithoutSpaces = props.label?.replace(/ /g, '');
  const { helperText, label, value, viewOnlyValue, register, registerRules, customError, ...rest } = props;

  return (
    <>
      <FormControl className={classes.root}>
        <Label label={label} helperText={helperText} component={viewOnlyValue ? 'span' : 'label'} />
        {!props.viewOnlyValue ? (
          <InputBase
            value={value}
            {...rest}
            className={`${classes.input} ${classes.content}`}
            id={labelWithoutSpaces}
            inputRef={register ? register(registerRules) : undefined}
          />
        ) : (
          <Typography className={classes.content}>{viewOnlyValue}</Typography>
        )}
      </FormControl>
      {customError && <Typography className={classes.error}>{customError.message}</Typography>}
    </>
  );
};
