import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  information: {
    marginTop: '1rem',
    '& > *': {
      marginBottom: '2rem',
    },
  },
}));
