import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const IDCard = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <g transform="translate(-869.773 -452)">
      <g transform="translate(52)">
        <rect width="24" height="24" transform="translate(818 452)" fill="none" />
      </g>
      <path
        d="M2.076,0A2,2,0,0,0,.605.607,2.009,2.009,0,0,0,0,2.081V15.571a2.015,2.015,0,0,0,.605,1.468,1.99,1.99,0,0,0,1.47.613H22.151a1.99,1.99,0,0,0,1.47-.613,2.015,2.015,0,0,0,.605-1.468V2.081A2.01,2.01,0,0,0,23.622.607,2,2,0,0,0,22.151,0ZM22.809,16.219H1.418V1.433h21.39ZM16.939,4.011a2.435,2.435,0,0,0-2.006.884,3.5,3.5,0,0,0-.715,2.272,3.434,3.434,0,0,0,1.349,2.717.292.292,0,0,1,.1.347l-.208.578a.672.672,0,0,1-.288.306,5.843,5.843,0,0,1-.807.318,5.759,5.759,0,0,0-1.026.277v1.931h7.2V11.71a6.211,6.211,0,0,0-1.015-.277,6.264,6.264,0,0,1-.813-.318.666.666,0,0,1-.294-.306l-.2-.578a.292.292,0,0,1,.1-.347,3.433,3.433,0,0,0,1.349-2.717A3.48,3.48,0,0,0,18.951,4.9,2.451,2.451,0,0,0,16.939,4.011ZM3.482,5.017v1.2h7.484v-1.2Zm0,3.214v1.2h7.484v-1.2Zm0,3.2v1.2h7.484v-1.2Z"
        transform="translate(869.773 454.711)"
      />
    </g>
  </SvgIcon>
);
