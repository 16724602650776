import React, { createContext } from 'react';
import { CardCategoryGroup, UserDetails } from '../Fetchers';

type AppState = {
  userDetails: UserDetails;
  categoryGroups: CardCategoryGroup[];
  seenCookieBanner?: 'true' | null;
};

export const InitialAppState: AppState = {
  userDetails: {
    name: localStorage.getItem('authenticatedName'),
    email: '',
    phoneNumber: '',
  },
  categoryGroups: [],
  seenCookieBanner: localStorage.getItem('seenCookieBanner') as 'true' | null,
};

export const AppContext = createContext<{
  state: AppState;
  dispatch: React.Dispatch<Action>;
}>({
  state: InitialAppState,
  dispatch: () => null,
});

interface Action {
  type: 'UPDATE_USER_DETAILS' | 'UPDATE_CATEGORY_GROUPS' | 'UPDATE_SEEN_COOKIE_BANNER';
  payload: any;
}

export const AppReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case 'UPDATE_USER_DETAILS':
      if (action.payload.name) {
        localStorage.setItem('authenticatedName', action.payload.name);
      }
      return { ...state, userDetails: { ...state.userDetails, ...action.payload } };
    case 'UPDATE_CATEGORY_GROUPS':
      return { ...state, categoryGroups: action.payload };
    case 'UPDATE_SEEN_COOKIE_BANNER':
      localStorage.setItem('seenCookieBanner', 'true');
      return { ...state, seenCookieBanner: action.payload };
    default:
      return state;
  }
};
