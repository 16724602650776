import React, { createContext, useState, useEffect, useContext } from 'react';

interface IBreadcrumbNavigationContext {
  setBreadcrumbState: (props: IBreadcrumState) => void;
  breadcrumbState: IBreadcrumState;
}

export interface IBreadcrumbItem {
  title: string;
  to?: string;
}

interface IBreadcrumState {
  breadcrumbNavigationItems: IBreadcrumbItem[];
  path: string;
}

const BreadcrumbNavigationContext = createContext<IBreadcrumbNavigationContext>({} as IBreadcrumbNavigationContext);
const useBreadcrumbNavigationContext = () => useContext(BreadcrumbNavigationContext);

interface IProps {
  children: React.ReactNode;
}

export function BreadcrumbNavigationContextProvider(props: IProps) {
  const [breadcrumbState, setBreadcrumbState] = useState<IBreadcrumState>({ breadcrumbNavigationItems: [], path: '' });
  const [breadcrumbNavigationContext, setBreadcrumbNavigationContext] = useState<IBreadcrumbNavigationContext>({
    breadcrumbState: breadcrumbState,
    setBreadcrumbState: setBreadcrumbState,
  });

  useEffect(() => {
    setBreadcrumbNavigationContext((prev) => ({
      ...prev,
      breadcrumbState: breadcrumbState,
    }));
  }, [breadcrumbState]);

  return (
    <BreadcrumbNavigationContext.Provider value={breadcrumbNavigationContext}>
      {props.children}
    </BreadcrumbNavigationContext.Provider>
  );
}

export default useBreadcrumbNavigationContext;
