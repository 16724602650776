import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  list: {
    padding: 0,
    listStyleType: 'none',
  },
  contact: {
    marginTop: '0.5rem',
  },
  contactTitle: {
    fontWeight: 600,
  },
}));
