import { Grid, Typography, Box, InputBase, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRequestResetPasswordMail } from '../../Fetchers';
import { useStyles } from '../login/LoginPage.styles';
import { ReturnToLogin } from '../../components/returntologin/ReturnToLogin';
import logo from '../../assets/logos/logo_black.svg';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const RequestPasswordEmailPage = () => {
  const classes = useStyles();
  const { register, handleSubmit, errors, setError } = useForm();
  const [showSuccess, setShowSuccess] = useState(false);
  const { mutate: requestEmail, loading } = useRequestResetPasswordMail({});
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = handleSubmit(async (data) => {
    if (!executeRecaptcha) {
      return setError('Email', { message: 'Tekniskt fel' });
    }

    const token = await executeRecaptcha();
    await requestEmail({ ...data, captchaToken: token });
    setShowSuccess(true);
  });

  return (
    <Grid className={classes.container} container justify="center" alignItems="center" component="main">
      <Grid item xl={2} lg={3} md={3} sm={5} xs={12}>
        <Box boxShadow={4} className={classes.root}>
          <img className={classes.logo} src={logo} alt="Logotyp för Keycode" />
          <Grid container justify="center">
            <Grid container item justify="flex-start">
              <Typography variant="h1" className={classes.header}>
                Glömt lösenord
              </Typography>
            </Grid>
            {!showSuccess ? (
              <form className={classes.form} noValidate onSubmit={onSubmit}>
                <>
                  <label className={classes.label} htmlFor="IdentificationNumber">
                    Personnummer
                  </label>
                  <InputBase
                    className={classes.input}
                    required
                    fullWidth
                    inputRef={register({
                      required: 'Måste vara ifyllt',
                      pattern: {
                        value: /^[\d]{12}$/,
                        message: 'Måste vara tolv siffor',
                      },
                    })}
                    name="IdentificationNumber"
                    placeholder="ÅÅÅÅMMDDXXXX"
                    id="IdentificationNumber"
                  />
                  {errors.IdentificationNumber && (
                    <Typography className={classes.error}>{errors.IdentificationNumber.message}</Typography>
                  )}
                  <label className={classes.label} htmlFor="Email">
                    E-post
                  </label>
                  <InputBase
                    className={classes.input}
                    required
                    fullWidth
                    placeholder="user@test.com"
                    inputRef={register({
                      pattern: { value: /^\S+@\S+\.\S+$/, message: 'Måste vara en giltlig e-post' },
                      required: true,
                    })}
                    name="Email"
                    id="Email"
                  />
                  {errors.Email && <Typography className={classes.error}>{errors.Email.message}</Typography>}
                  <Button
                    type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={loading}
                  >
                    Återställ lösenord
                  </Button>
                </>
              </form>
            ) : (
              <ReturnToLogin message="Om e-posten finns registrerad har ett mail skickat med återställningslänk." />
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
