import React from 'react';
import { NavLink } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { useStyles } from './Breadcrumbs.styles';
import useBreadcrumbNavigationContext from './breadcrumbsNavigationContext/BreadcrumbNavigationContext';

export const Breadcrumbs = () => {
  const {
    breadcrumbState: { breadcrumbNavigationItems },
  } = useBreadcrumbNavigationContext();
  const classes = useStyles();

  return breadcrumbNavigationItems.length > 0 ? (
    <nav>
      <ol className={classes.list}>
        {breadcrumbNavigationItems.map((link, index) => (
          <li key={link.title} className={classes.listItem}>
            {link.to && (
              <NavLink exact={true} to={link.to} aria-current="page" className={classes.link}>
                <Typography variant="body1" component="span">
                  {link.title}
                </Typography>
              </NavLink>
            )}
            {!link.to && (
              <Typography variant="body1" component="span">
                {link.title}
              </Typography>
            )}
            {index < breadcrumbNavigationItems.length - 1 && <span className={classes.divider}>/</span>}
          </li>
        ))}
      </ol>
    </nav>
  ) : (
    <span></span>
  );
};

export default Breadcrumbs;
