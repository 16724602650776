import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Phone = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <g transform="translate(-764 -452)">
      <rect width="24" height="24" transform="translate(764 452)" fill="none" />
      <g transform="translate(768.781 452)">
        <g>
          <path
            d="M11.551,1.48H2.888a1.426,1.426,0,0,0-1.021.434,1.5,1.5,0,0,0-.423,1.047V20.724a1.5,1.5,0,0,0,.423,1.047,1.426,1.426,0,0,0,1.021.434h8.663a1.426,1.426,0,0,0,1.021-.434,1.5,1.5,0,0,0,.423-1.047V2.961a1.5,1.5,0,0,0-.423-1.047,1.426,1.426,0,0,0-1.021-.434M2.888,0A2.853,2.853,0,0,0,.846.867,3,3,0,0,0,0,2.961V20.724a3,3,0,0,0,.846,2.093,2.853,2.853,0,0,0,2.042.867h8.663a2.852,2.852,0,0,0,2.042-.867,3,3,0,0,0,.846-2.093V2.961A3,3,0,0,0,13.593.867,2.852,2.852,0,0,0,11.551,0Z"
            fillRule="evenodd"
          />
          <path
            d="M36.694,108.711a1.425,1.425,0,0,0,1.021-.434,1.507,1.507,0,0,0,0-2.094,1.419,1.419,0,0,0-2.042,0,1.507,1.507,0,0,0,0,2.094,1.426,1.426,0,0,0,1.021.434"
            transform="translate(-29.475 -87.986)"
            fillRule="evenodd"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);
