import React from 'react';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab/Fab';
import { useStyles } from './CookieBanner.styles';
import CloseIcon from '@material-ui/icons/Close';
import { Box } from '@material-ui/core';

export interface ICookies {
  onClose?: () => void;
}

export const CookieBanner = (props: ICookies) => {
  const classes = useStyles();

  return (
    <Box boxShadow={9} className={classes.container}>
      <div className={classes.root}>
        <section>
          <Typography variant="subtitle1">Cookies</Typography>
          <Typography>
            Vi har placerat cookies i din webbläsare och lagrar ditt IP-nummer för att ge dig en bättre upplevelse av
            vår webbplats.
          </Typography>
        </section>
        <span>
          <Fab
            size="medium"
            color="primary"
            className={classes.close}
            onClick={props.onClose}
            aria-label="Stäng information om cookies"
          >
            <CloseIcon />
          </Fab>
        </span>
      </div>
    </Box>
  );
};
