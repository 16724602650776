import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { useStyles } from './ConfirmRemoveDialog.styles';
import { Typography } from '@material-ui/core';
import { DeleteOutlineRounded } from '@material-ui/icons';

export interface ConfirmationDialogRawProps {
  name: string;
  confirmRemove: () => void;
}

export const ConfirmRemoveDialog = (props: ConfirmationDialogRawProps) => {
  const classes = useStyles();

  function confirmRemoveRow() {
    setOpen(false);
    props.confirmRemove();
  }

  const [open, setOpen] = useState(false);

  const labelledby = props.name.replace(/ /g, '');

  return (
    <>
      <Button className={classes.remove} aria-label={`Ta bort ${props.name}`} onClick={() => setOpen(true)}>
        <DeleteOutlineRounded />
      </Button>
      <Dialog
        maxWidth="xs"
        onEscapeKeyDown={() => setOpen(false)}
        onBackdropClick={() => setOpen(false)}
        aria-labelledby={labelledby}
        open={open}
      >
        <DialogTitle disableTypography id={labelledby}>
          <Typography variant="h3" className={classes.title} component="h2">
            Ta bort
          </Typography>
        </DialogTitle>
        <DialogContent>Är du säker på att du vill ta bort {props.name}?</DialogContent>
        <DialogActions className={classes.action}>
          <Button autoFocus onClick={() => setOpen(false)}>
            Avbryt
          </Button>
          <Button variant="contained" className={classes.confirm} onClick={confirmRemoveRow} color="primary">
            Ta bort
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
