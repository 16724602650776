import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 700,
  },
  action: {
    marginTop: '0.5rem',
  },
  confirm: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  remove: {
    padding: '0.5rem',
    minWidth: 0,
    color: theme.palette.error.main,
  },
}));
