import React from 'react';
import { Button, Grid, Hidden, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useStyles } from './FeatureCard.styles';

interface IFeatureCard {
  title: string;
  icon: React.ReactNode;
  link: ILink;
}

interface ILink {
  text: string;
  to: string;
}

export const FeatureCard: React.FC<IFeatureCard> = (props) => {
  const classes = useStyles();

  return (
    <Grid item xl={3} lg={4} md={6} xs={12} component="li">
      <Grid container className={classes.root}>
        <Hidden mdDown>
          <Grid item lg={5} container>
            <div className={classes.icon}>{props.icon}</div>
          </Grid>
        </Hidden>
        <Grid item lg={7} xs={12} className={classes.content}>
          <Typography component="h2" variant="h3" className={classes.title}>
            <Hidden lgUp>
              <div className={classes.icon}>{props.icon}</div>
            </Hidden>
            {props.title}
          </Typography>
          <div className={classes.children}>{props.children}</div>
          <Button component={Link} to={props.link.to} className={classes.link} variant="contained" color="primary">
            {props.link.text}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
