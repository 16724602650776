import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: theme.typography.h3.fontSize,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginRight: '2rem',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  helperText: {
    fontSize: '1rem',
  },
}));
