/* Generated by restful-react */

import React from 'react';
import { Get, GetProps, useGet, UseGetProps, Mutate, MutateProps, useMutate, UseMutateProps } from 'restful-react';
export interface LoginCommand {
  identificationNumber?: string | null;
  password?: string | null;
  captchaToken?: string | null;
}

export interface LoggedInUser {
  name?: string | null;
}

export interface LoginResult {
  loggedInUser?: LoggedInUser;
}

export interface ActivationCodeLoginCommand {
  identificationNumber?: string | null;
  activationCode?: string | null;
  captchaToken?: string | null;
}

export interface ActivationCodeLoginResult {
  loggedInUser?: LoggedInUser;
}

export interface ResetPasswordEmailRequestCommand {
  email?: string | null;
  identificationNumber?: string | null;
  captchaToken?: string | null;
}

export interface SetNewPasswordCommand {
  password?: string | null;
  resetCode?: string | null;
  captchaToken?: string | null;
}

export interface ErrorListObject {
  name?: string | null;
  error?: string | null;
}

export interface ValidationError {
  type?: string | null;
  errors?: ErrorListObject[] | null;
}

export interface LogoutCommand {
  [key: string]: any;
}

export interface ProtectionObjectViewModel {
  objectId?: number;
  cardNumber?: string | null;
  additionalInformation?: string | null;
  ownerName?: string | null;
  ownerIdentification?: string | null;
  issuerName?: string | null;
  cardTypeName?: string | null;
  cardGroupCategoryName?: string | null;
  cardGroupCategoryId?: number;
}

export interface ProtectionObjectGroupResult {
  groupId?: number;
  groupName?: string | null;
  cards?: ProtectionObjectViewModel[] | null;
}

export interface GetProtectionObjectsResult {
  groupResult?: ProtectionObjectGroupResult[] | null;
}

export interface GetProtectionObjectsByCategoryGroupResult {
  groupResult?: ProtectionObjectGroupResult[] | null;
}

export interface IdName {
  id?: number;
  name?: string | null;
}

export interface GetIssuersByCategoryGroupResult {
  idNameList?: IdName[] | null;
}

export interface GetCardTypesByCategoryGroupAndIssuerResult {
  idNameList?: IdName[] | null;
}

export type Validations = number;

export interface CardViewModel {
  id?: number;
  validation?: Validations;
  canBeCancelled?: boolean;
}

export interface GetCardResult {
  cardResult?: CardViewModel;
}

export interface CardCategoryGroup {
  id?: number;
  name?: string | null;
  maxCount?: number | null;
}

export interface GetCardCategoryGroupsResult {
  cardCategoryGroups?: CardCategoryGroup[] | null;
}

export interface CreateProtectionObjectCommand {
  cardId?: number;
  otherOwnerName?: string | null;
  otherOwnerIdentification?: string | null;
  cardNumber?: string | null;
  additionalInformation?: string | null;
  groupId?: number;
}

export interface DeleteProtectionObjectCommand {
  objectId?: number;
}

export interface UserDetails {
  name?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
}

export interface UserDetailsResult {
  userDetails?: UserDetails;
}

export interface RegisterUserCommand {
  activationCode?: string | null;
  email?: string | null;
  name?: string | null;
  password?: string | null;
  identificationNumber?: string | null;
  phoneNumber?: string | null;
  captchaToken?: string | null;
}

export interface UpdateUserDetailsCommand {
  password?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
}

export type LoginProps = Omit<MutateProps<LoginResult, unknown, void, LoginCommand, void>, 'path' | 'verb'>;

export const Login = (props: LoginProps) => (
  <Mutate<LoginResult, unknown, void, LoginCommand, void> verb="POST" path={`/Login`} {...props} />
);

export type UseLoginProps = Omit<UseMutateProps<LoginResult, unknown, void, LoginCommand, void>, 'path' | 'verb'>;

export const useLogin = (props: UseLoginProps) =>
  useMutate<LoginResult, unknown, void, LoginCommand, void>('POST', `/Login`, props);

export type ActivationCodeLoginProps = Omit<
  MutateProps<ActivationCodeLoginResult, unknown, void, ActivationCodeLoginCommand, void>,
  'path' | 'verb'
>;

export const ActivationCodeLogin = (props: ActivationCodeLoginProps) => (
  <Mutate<ActivationCodeLoginResult, unknown, void, ActivationCodeLoginCommand, void>
    verb="POST"
    path={`/Login/activation-code`}
    {...props}
  />
);

export type UseActivationCodeLoginProps = Omit<
  UseMutateProps<ActivationCodeLoginResult, unknown, void, ActivationCodeLoginCommand, void>,
  'path' | 'verb'
>;

export const useActivationCodeLogin = (props: UseActivationCodeLoginProps) =>
  useMutate<ActivationCodeLoginResult, unknown, void, ActivationCodeLoginCommand, void>(
    'POST',
    `/Login/activation-code`,
    props,
  );

export type RequestResetPasswordMailProps = Omit<
  MutateProps<void, unknown, void, ResetPasswordEmailRequestCommand, void>,
  'path' | 'verb'
>;

export const RequestResetPasswordMail = (props: RequestResetPasswordMailProps) => (
  <Mutate<void, unknown, void, ResetPasswordEmailRequestCommand, void>
    verb="POST"
    path={`/Login/request-reset-password`}
    {...props}
  />
);

export type UseRequestResetPasswordMailProps = Omit<
  UseMutateProps<void, unknown, void, ResetPasswordEmailRequestCommand, void>,
  'path' | 'verb'
>;

export const useRequestResetPasswordMail = (props: UseRequestResetPasswordMailProps) =>
  useMutate<void, unknown, void, ResetPasswordEmailRequestCommand, void>(
    'POST',
    `/Login/request-reset-password`,
    props,
  );

export type SetNewPasswordProps = Omit<
  MutateProps<void, ValidationError, void, SetNewPasswordCommand, void>,
  'path' | 'verb'
>;

export const SetNewPassword = (props: SetNewPasswordProps) => (
  <Mutate<void, ValidationError, void, SetNewPasswordCommand, void>
    verb="POST"
    path={`/Login/set-new-password`}
    {...props}
  />
);

export type UseSetNewPasswordProps = Omit<
  UseMutateProps<void, ValidationError, void, SetNewPasswordCommand, void>,
  'path' | 'verb'
>;

export const useSetNewPassword = (props: UseSetNewPasswordProps) =>
  useMutate<void, ValidationError, void, SetNewPasswordCommand, void>('POST', `/Login/set-new-password`, props);

export type LogoutProps = Omit<MutateProps<void, unknown, void, LogoutCommand, void>, 'path' | 'verb'>;

export const Logout = (props: LogoutProps) => (
  <Mutate<void, unknown, void, LogoutCommand, void> verb="POST" path={`/Login/logout`} {...props} />
);

export type UseLogoutProps = Omit<UseMutateProps<void, unknown, void, LogoutCommand, void>, 'path' | 'verb'>;

export const useLogout = (props: UseLogoutProps) =>
  useMutate<void, unknown, void, LogoutCommand, void>('POST', `/Login/logout`, props);

export type GetAllProtectionObjectsProps = Omit<GetProps<GetProtectionObjectsResult, unknown, void, void>, 'path'>;

export const GetAllProtectionObjects = (props: GetAllProtectionObjectsProps) => (
  <Get<GetProtectionObjectsResult, unknown, void, void> path={`/ProtectionObject/all`} {...props} />
);

export type UseGetAllProtectionObjectsProps = Omit<
  UseGetProps<GetProtectionObjectsResult, unknown, void, void>,
  'path'
>;

export const useGetAllProtectionObjects = (props: UseGetAllProtectionObjectsProps) =>
  useGet<GetProtectionObjectsResult, unknown, void, void>(`/ProtectionObject/all`, props);

export interface GetAllProtectionObjectsByCategoryGroupPathParams {
  groupId: number;
}

export type GetAllProtectionObjectsByCategoryGroupProps = Omit<
  GetProps<GetProtectionObjectsByCategoryGroupResult, unknown, void, GetAllProtectionObjectsByCategoryGroupPathParams>,
  'path'
> &
  GetAllProtectionObjectsByCategoryGroupPathParams;

export const GetAllProtectionObjectsByCategoryGroup = ({
  groupId,
  ...props
}: GetAllProtectionObjectsByCategoryGroupProps) => (
  <Get<GetProtectionObjectsByCategoryGroupResult, unknown, void, GetAllProtectionObjectsByCategoryGroupPathParams>
    path={`/ProtectionObject/group/${groupId}`}
    {...props}
  />
);

export type UseGetAllProtectionObjectsByCategoryGroupProps = Omit<
  UseGetProps<
    GetProtectionObjectsByCategoryGroupResult,
    unknown,
    void,
    GetAllProtectionObjectsByCategoryGroupPathParams
  >,
  'path'
> &
  GetAllProtectionObjectsByCategoryGroupPathParams;

export const useGetAllProtectionObjectsByCategoryGroup = ({
  groupId,
  ...props
}: UseGetAllProtectionObjectsByCategoryGroupProps) =>
  useGet<GetProtectionObjectsByCategoryGroupResult, unknown, void, GetAllProtectionObjectsByCategoryGroupPathParams>(
    (paramsInPath: GetAllProtectionObjectsByCategoryGroupPathParams) =>
      `/ProtectionObject/group/${paramsInPath.groupId}`,
    { pathParams: { groupId }, ...props },
  );

export interface GetAllIssuersByCategoryGroupPathParams {
  groupId: number;
}

export type GetAllIssuersByCategoryGroupProps = Omit<
  GetProps<GetIssuersByCategoryGroupResult, unknown, void, GetAllIssuersByCategoryGroupPathParams>,
  'path'
> &
  GetAllIssuersByCategoryGroupPathParams;

export const GetAllIssuersByCategoryGroup = ({ groupId, ...props }: GetAllIssuersByCategoryGroupProps) => (
  <Get<GetIssuersByCategoryGroupResult, unknown, void, GetAllIssuersByCategoryGroupPathParams>
    path={`/ProtectionObject/group/${groupId}/issuers`}
    {...props}
  />
);

export type UseGetAllIssuersByCategoryGroupProps = Omit<
  UseGetProps<GetIssuersByCategoryGroupResult, unknown, void, GetAllIssuersByCategoryGroupPathParams>,
  'path'
> &
  GetAllIssuersByCategoryGroupPathParams;

export const useGetAllIssuersByCategoryGroup = ({ groupId, ...props }: UseGetAllIssuersByCategoryGroupProps) =>
  useGet<GetIssuersByCategoryGroupResult, unknown, void, GetAllIssuersByCategoryGroupPathParams>(
    (paramsInPath: GetAllIssuersByCategoryGroupPathParams) => `/ProtectionObject/group/${paramsInPath.groupId}/issuers`,
    { pathParams: { groupId }, ...props },
  );

export interface GetAllCardTypesByCategoryGroupAndIssuerPathParams {
  groupId: number;
  issuerId: number;
}

export type GetAllCardTypesByCategoryGroupAndIssuerProps = Omit<
  GetProps<
    GetCardTypesByCategoryGroupAndIssuerResult,
    unknown,
    void,
    GetAllCardTypesByCategoryGroupAndIssuerPathParams
  >,
  'path'
> &
  GetAllCardTypesByCategoryGroupAndIssuerPathParams;

export const GetAllCardTypesByCategoryGroupAndIssuer = ({
  groupId,
  issuerId,
  ...props
}: GetAllCardTypesByCategoryGroupAndIssuerProps) => (
  <Get<GetCardTypesByCategoryGroupAndIssuerResult, unknown, void, GetAllCardTypesByCategoryGroupAndIssuerPathParams>
    path={`/ProtectionObject/group/${groupId}/issuer/${issuerId}/types`}
    {...props}
  />
);

export type UseGetAllCardTypesByCategoryGroupAndIssuerProps = Omit<
  UseGetProps<
    GetCardTypesByCategoryGroupAndIssuerResult,
    unknown,
    void,
    GetAllCardTypesByCategoryGroupAndIssuerPathParams
  >,
  'path'
> &
  GetAllCardTypesByCategoryGroupAndIssuerPathParams;

export const useGetAllCardTypesByCategoryGroupAndIssuer = ({
  groupId,
  issuerId,
  ...props
}: UseGetAllCardTypesByCategoryGroupAndIssuerProps) =>
  useGet<GetCardTypesByCategoryGroupAndIssuerResult, unknown, void, GetAllCardTypesByCategoryGroupAndIssuerPathParams>(
    (paramsInPath: GetAllCardTypesByCategoryGroupAndIssuerPathParams) =>
      `/ProtectionObject/group/${paramsInPath.groupId}/issuer/${paramsInPath.issuerId}/types`,
    { pathParams: { groupId, issuerId }, ...props },
  );

export interface GetCardPathParams {
  issuerId: number;
  cardTypeId: number;
}

export type GetCardProps = Omit<GetProps<GetCardResult, unknown, void, GetCardPathParams>, 'path'> & GetCardPathParams;

export const GetCard = ({ issuerId, cardTypeId, ...props }: GetCardProps) => (
  <Get<GetCardResult, unknown, void, GetCardPathParams>
    path={`/ProtectionObject/issuer/${issuerId}/type/${cardTypeId}/card`}
    {...props}
  />
);

export type UseGetCardProps = Omit<UseGetProps<GetCardResult, unknown, void, GetCardPathParams>, 'path'> &
  GetCardPathParams;

export const useGetCard = ({ issuerId, cardTypeId, ...props }: UseGetCardProps) =>
  useGet<GetCardResult, unknown, void, GetCardPathParams>(
    (paramsInPath: GetCardPathParams) =>
      `/ProtectionObject/issuer/${paramsInPath.issuerId}/type/${paramsInPath.cardTypeId}/card`,
    { pathParams: { issuerId, cardTypeId }, ...props },
  );

export type GetAllCardCategoryGroupsProps = Omit<GetProps<GetCardCategoryGroupsResult, unknown, void, void>, 'path'>;

export const GetAllCardCategoryGroups = (props: GetAllCardCategoryGroupsProps) => (
  <Get<GetCardCategoryGroupsResult, unknown, void, void> path={`/ProtectionObject/groups`} {...props} />
);

export type UseGetAllCardCategoryGroupsProps = Omit<
  UseGetProps<GetCardCategoryGroupsResult, unknown, void, void>,
  'path'
>;

export const useGetAllCardCategoryGroups = (props: UseGetAllCardCategoryGroupsProps) =>
  useGet<GetCardCategoryGroupsResult, unknown, void, void>(`/ProtectionObject/groups`, props);

export type CreateProtectionObjectProps = Omit<
  MutateProps<void, ValidationError, void, CreateProtectionObjectCommand, void>,
  'path' | 'verb'
>;

export const CreateProtectionObject = (props: CreateProtectionObjectProps) => (
  <Mutate<void, ValidationError, void, CreateProtectionObjectCommand, void>
    verb="POST"
    path={`/ProtectionObject`}
    {...props}
  />
);

export type UseCreateProtectionObjectProps = Omit<
  UseMutateProps<void, ValidationError, void, CreateProtectionObjectCommand, void>,
  'path' | 'verb'
>;

export const useCreateProtectionObject = (props: UseCreateProtectionObjectProps) =>
  useMutate<void, ValidationError, void, CreateProtectionObjectCommand, void>('POST', `/ProtectionObject`, props);

export type DeleteProtectionObjectProps = Omit<
  MutateProps<void, ValidationError, void, DeleteProtectionObjectCommand, void>,
  'path' | 'verb'
>;

export const DeleteProtectionObject = (props: DeleteProtectionObjectProps) => (
  <Mutate<void, ValidationError, void, DeleteProtectionObjectCommand, void>
    verb="DELETE"
    path={`/ProtectionObject`}
    {...props}
  />
);

export type UseDeleteProtectionObjectProps = Omit<
  UseMutateProps<void, ValidationError, void, DeleteProtectionObjectCommand, void>,
  'path' | 'verb'
>;

export const useDeleteProtectionObject = (props: UseDeleteProtectionObjectProps) =>
  useMutate<void, ValidationError, void, DeleteProtectionObjectCommand, void>('DELETE', `/ProtectionObject`, {
    ...props,
  });

export type GetUserProps = Omit<GetProps<UserDetailsResult, unknown, void, void>, 'path'>;

export const GetUser = (props: GetUserProps) => (
  <Get<UserDetailsResult, unknown, void, void> path={`/User`} {...props} />
);

export type UseGetUserProps = Omit<UseGetProps<UserDetailsResult, unknown, void, void>, 'path'>;

export const useGetUser = (props: UseGetUserProps) => useGet<UserDetailsResult, unknown, void, void>(`/User`, props);

export type RegisterUserProps = Omit<
  MutateProps<void, ValidationError, void, RegisterUserCommand, void>,
  'path' | 'verb'
>;

export const RegisterUser = (props: RegisterUserProps) => (
  <Mutate<void, ValidationError, void, RegisterUserCommand, void> verb="POST" path={`/User`} {...props} />
);

export type UseRegisterUserProps = Omit<
  UseMutateProps<void, ValidationError, void, RegisterUserCommand, void>,
  'path' | 'verb'
>;

export const useRegisterUser = (props: UseRegisterUserProps) =>
  useMutate<void, ValidationError, void, RegisterUserCommand, void>('POST', `/User`, props);

export type UpdateUserProps = Omit<
  MutateProps<void, ValidationError, void, UpdateUserDetailsCommand, void>,
  'path' | 'verb'
>;

export const UpdateUser = (props: UpdateUserProps) => (
  <Mutate<void, ValidationError, void, UpdateUserDetailsCommand, void> verb="PUT" path={`/User`} {...props} />
);

export type UseUpdateUserProps = Omit<
  UseMutateProps<void, ValidationError, void, UpdateUserDetailsCommand, void>,
  'path' | 'verb'
>;

export const useUpdateUser = (props: UseUpdateUserProps) =>
  useMutate<void, ValidationError, void, UpdateUserDetailsCommand, void>('PUT', `/User`, props);
