import { makeStyles, Theme } from '@material-ui/core';
import { StylesConfig } from 'react-select';
import theme from '../../style/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

export const styles = {
  control: (base, state: any) => {
    const borderColor = state.isFocused ? theme.palette.primary.main : theme.palette.grey[700];
    const isDisabled = state.isDisabled ? 0.5 : 1;
    return {
      border: `1px solid ${borderColor}`,
      color: theme.palette.grey[900],
      borderRadius: theme.shape.borderRadius,
      display: 'inline-flex',
      cursor: 'text',
      fontSize: '1rem',
      alignItems: 'center',
      minWidth: theme.breakpoints.up('sm') ? '20rem' : 'none',
      width: theme.breakpoints.down('xs') ? '100%' : 'auto',
      padding: '0.75rem 0.5rem 0.75rem 0',
      background: theme.palette.common.white,
      opacity: isDisabled,
      boxSizing: 'border-box',
      zIndex: 2,
    };
  },

  input: () =>
    ({
      font: 'inherit',
      color: 'currentColor',
      margin: 0,
      display: 'block',
      minWidth: '0',
      fontSize: '1rem',
      boxSizing: 'content-box',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      backgroundColor: theme.palette.common.white,
      webkitTapHighlightColor: 'transparent',
    } as React.CSSProperties),
  container: () => ({
    position: 'relative',
    flexGrow: 1,
  }),
  valueContainer: () => ({
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    webkitOverflowScrolling: 'touch',
    position: 'relative',
    overflow: 'hidden',
    boxSizing: 'border-box',
  }),
  dropdownIndicator: () => ({
    cursor: 'pointer',
    color: theme.palette.grey[600],
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      height: '1rem',
    },
  }),
  indicatorSeparator: () => ({}),
  placeholder: () => ({
    position: 'absolute',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    fontSize: '1rem',
    color: theme.palette.grey[600],
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    height: '100%',
    alignSelf: 'normal',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      top: '20%',
    },
  }),
  singleValue: () => ({
    position: 'absolute',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    fontSize: '1rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    height: '100%',
    alignSelf: 'normal',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      top: '20%',
    },
  }),
  menu: () => {
    return {
      backgroundColor: '#fff',
      width: '100%',
      position: 'absolute',
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: `0 0 ${theme.shape.borderRadius} ${theme.shape.borderRadius}`,
      zIndex: 10,
      boxShadow: `0px 2px 5px 0px ${theme.palette.grey[300]}`,
    };
  },
  multiValueLabel: () => ({
    webkitAlignItems: 'center',
    webkitBoxAlign: 'center',
    msFlexAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    webkitFlexWrap: 'wrap',
    msFlexWap: 'wrap',
    flexWrap: 'wrap',
    padding: '0.25rem 0 0.25rem 0.4rem',
    webkitOverflowScrolling: 'touch',
    position: 'relative',
    overflow: 'hidden',
  }),
  multiValue: () => ({
    backgroundColor: theme.palette.grey[200],
    borderRadius: '1rem',
    display: 'flex',
    margin: '0.25rem 0 0.25rem 0.5rem',
    minWidth: 0,
  }),
  option: (state: any) => ({
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    fontSize: '1rem',
    backgroundColor: state.isFocused ? theme.palette.grey[200] : theme.palette.common.white,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey[200],
    },
  }),
  clearIndicator: () => ({
    cursor: 'pointer',
    marginRight: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.grey[100],
    borderRadius: '50%',
    '& > svg': {
      height: '1.2rem',
      width: '1.2rem',
    },
  }),
  loadingIndicator: () => ({
    display: 'flex',
    padding: '0.25rem',
    transition: 'color 150ms ease 0s',
    alignSelf: 'center',
    fontSize: '4px',
    lineHeight: 1,
    marginRight: '4px',
    textAlign: 'center',
    verticalAlign: 'middle',
    boxSizing: 'border-box',
  }),
  multiValueRemove: () => ({
    alignItems: 'center',
    display: 'flex',
    paddingLeft: '0.4rem',
    paddingRight: '0.4rem',
    boxSizing: 'border-box',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.error.main,
    },
  }),
} as StylesConfig;
