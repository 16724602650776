import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  list: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      margin: '0 0 0.5rem',
    },
  },
  listItem: {
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '& > span': {
      borderBottom: '1px solid transparent',
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  divider: {
    margin: '0 0.25rem',
  },
}));
