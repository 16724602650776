import { makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import theme from '../../style/theme';

export const errorMessage = {
  minWidth: '100%',
  fontSize: theme.typography.h4.fontSize,
  color: theme.palette.error.main,
  marginTop: '-0.75rem',
  [theme.breakpoints.up('sm')]: {
    minWidth: '20rem',
  },
} as CSSProperties;

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  input: {
    border: '1px solid',
    borderColor: theme.palette.grey[700],
    borderRadius: theme.shape.borderRadius,
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    '&:focus-within': {
      borderColor: theme.palette.primary.main,
    },
  },
  content: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    minWidth: '100%',
    fontSize: theme.typography.h3.fontSize,
    [theme.breakpoints.up('sm')]: {
      minWidth: '20rem',
    },
  },
  error: {
    ...errorMessage,
  },
}));
