import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    ...theme.mixins.toolbar,
    backgroundColor: theme.palette.grey[900],
    padding: '0 1rem',
    display: 'flex',
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse',
    },
  },
  logo: {
    height: '2rem',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  close: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    '& > *': {
      color: theme.palette.background.default,
    },
  },
  contact: {
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      '& a': {
        color: theme.palette.primary.light,
        textDecoration: 'none',
      },
    },
  },
  icon: {
    marginRight: '0.25rem',
    fontSize: theme.typography.caption.fontSize,
  },
}));
