import { useLogout } from '../Fetchers';

export const useLogoutHook = () => {
  const { mutate: Logout } = useLogout({});

  const onLogout = async () => {
    await Logout({});
    localStorage.removeItem('authenticatedName');
    window.location.href = '/login';
  };

  return { onLogout };
};
