import { makeStyles, Theme } from '@material-ui/core';
import { commonBorder } from '../../../../style/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: '1rem',
    border: commonBorder,
    height: '100%',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  children: {
    flexGrow: 1,
  },
  icon: {
    paddingRight: '0.5rem',
    display: 'flex',
    maxHeight: '14rem',
    '& > *': {
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('lg')]: {
      '& > svg': {
        width: '100%',
        height: '100%',
      },
    },
  },
  link: {
    marginTop: '1rem',
  },
}));
