import React from 'react';
import { Phone } from '../assets/icons/Phone';
import { CreditCard } from '../assets/icons/CreditCard';
import { IDCard } from '../assets/icons/IDCard';
import { Tag } from '../assets/icons/Tag';
import { Calculator } from '../assets/icons/Calculator';
import { BarChart } from '@material-ui/icons';

export enum CardCategoryGroups {
  CellPhone = 1,
  Subscription = 2,
  Payment = 3,
  Identification = 4,
  Library = 5,
  BankCardReader = 6,
}

export const getIconByCategoryGroupId = (group: CardCategoryGroups) => {
  switch (group) {
    case CardCategoryGroups.CellPhone:
      return <Phone />;
    case CardCategoryGroups.Subscription:
      return <BarChart />;
    case CardCategoryGroups.Payment:
      return <CreditCard />;
    case CardCategoryGroups.Identification:
      return <IDCard />;
    case CardCategoryGroups.Library:
      return <Tag />;
    case CardCategoryGroups.BankCardReader:
      return <Calculator />;
    default:
      break;
  }
};

interface Ingress {
  MainIngress: string;
  SubIngress: string;
}

export const getIngressByCategoryGroupId = (group: CardCategoryGroups): Ingress => {
  switch (group) {
    case CardCategoryGroups.CellPhone:
      return {
        MainIngress: 'Här registrerar du din fysiska mobiltelefon. Du kan även se vad du tidigare registrerat.',
        SubIngress: 'Mobiltelefonen registerar du genom att mata in dess identifikationsnummer, IMEI-nummer',
      };
    case CardCategoryGroups.Subscription:
      return {
        MainIngress:
          'Här registrerar du dina mobilabonnemang och mobila bredband. Du kan även se vad du tidigare registrerat.',
        SubIngress: '',
      };
    case CardCategoryGroups.Payment:
      return {
        MainIngress: 'Här registrerar du dina konto- och betalkort. Du kan även se vad du tidigare registrerat.',
        SubIngress: 'Exempel på utställare är din bank som Nordea, Swedbank med flera.',
      };
    case CardCategoryGroups.Identification:
      return {
        MainIngress: 'Här registrerar du dina ID- och körkort. Du kan även se vad du tidigare registrerat.',
        SubIngress: '',
      };
    case CardCategoryGroups.Library:
      return {
        MainIngress: 'Här registrerar du dina lånekort. Du kan även se vad du tidigare registrerat.',
        SubIngress: '',
      };
    case CardCategoryGroups.BankCardReader:
      return {
        MainIngress:
          'Här registrerar du dina banksäkerhetsdosor, digipass, etc. Du kan även se vad du tidigare registrerat.',
        SubIngress: '',
      };
    default:
      return { MainIngress: '', SubIngress: '' };
  }
};
