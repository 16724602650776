import React from 'react';
import { FormControl, Grid, RadioGroup as MuiRadioGroup } from '@material-ui/core';
import { Label } from '../label/Label';
import { useStyles } from './RadioGroup.styles';

interface IRadioGroupProps {
  label: string;
  value: string;
  onChange: (data: string) => void;
}

export const RadioGroup: React.FC<IRadioGroupProps> = (props) => {
  const classes = useStyles();
  return (
    <FormControl component="fieldset">
      <Grid container alignItems="center" className={classes.root}>
        <Label component="legend" label={props.label} />
        <MuiRadioGroup
          value={props.value}
          onChange={(event) => props.onChange(event.target.value)}
          className={classes.radioGroup}
          row
          aria-label="position"
          name="position"
          defaultValue="top"
        >
          {props.children}
        </MuiRadioGroup>
      </Grid>
    </FormControl>
  );
};
