import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './PageHeader.styles';

interface IPageHeader {
  title: string;
  icon: React.ReactNode;
}

export const PageHeader: React.FC<IPageHeader> = (props) => {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <Grid className={classes.titleContainer} container alignItems="center">
        <div className={classes.icon}>{props.icon}</div>
        <Typography variant="h1" className={classes.h1}>
          {props.title}
        </Typography>
      </Grid>
      <div className={classes.children}>{props.children}</div>
    </header>
  );
};
