import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  signOutBtn: {
    backgroundColor: theme.palette.secondary.light,
    width: '100%',
    padding: '0.75rem 1rem',
    justifyContent: 'flex-start',
    borderRadius: 0,
  },
}));
