import { makeStyles, Theme } from '@material-ui/core';
import { commonBorder } from '../../style/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: '1.5rem',
    backgroundColor: theme.palette.grey[400],
    borderBottom: commonBorder,
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem 0.5rem',
    },
  },
  h1: {
    fontWeight: 700,
  },
  titleContainer: {
    marginBottom: '1rem',
  },
  icon: {
    display: 'flex',
    marginRight: '0.5rem',
    color: theme.palette.secondary.main,
    '* > svg': {
      fontSize: '2.5rem',
    },
  },
  children: {
    '& > *': {
      fontSize: theme.typography.h3.fontSize,
      marginBottom: '0.5rem',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    '& a': {
      fontWeight: 700,
      textDecoration: 'none',
      color: theme.palette.common.black,
    },
  },
}));
