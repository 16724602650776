import React from 'react';
import { useStyles } from './SideMenuHeader.styles';
import logo from '../../../assets/logos/logo_white.svg';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { PhoneRounded, EmailRounded, CloseRounded } from '@material-ui/icons';

interface ISideMenuHeaderProps {
  closeMenu?: () => void;
}

export const SideMenuHeader = (props: ISideMenuHeaderProps) => {
  const classes = useStyles();

  return (
    <div className={classes.toolbar}>
      <Grid container className={classes.container} justify="space-between" alignItems="center">
        <Grid item>
          <img className={classes.logo} src={logo} alt="Logotype" />
          <IconButton className={classes.close} aria-label="Stäng meny" onClick={props.closeMenu}>
            <CloseRounded />
          </IconButton>
        </Grid>
        <Grid item className={classes.contact}>
          <Typography variant="caption">
            <PhoneRounded className={classes.icon} /> <a href="tel:+46317586100">031-758 61 00</a>
          </Typography>
          <Typography variant="caption">
            <EmailRounded className={classes.icon} />
            <a href="mailto:nfo@keycode.se">info@keycode.se</a>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
