import React from 'react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Done } from '@material-ui/icons';

interface IReturnToLoginProps {
  message: string;
}

const useStyles = makeStyles(() => ({
  submit: {
    margin: '1.5rem 0 1rem',
  },
  icon: {
    marginRight: '0.25rem',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const ReturnToLogin: React.FC<IReturnToLoginProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid container item justify="flex-start" direction="column">
      <Typography className={classes.text} align="left">
        <Done className={classes.icon} />
        {props.message}
      </Typography>
      <Button
        type="submit"
        fullWidth
        component={Link}
        to="/"
        size="large"
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        Gå till logga in
      </Button>
    </Grid>
  );
};
