import React from 'react';
import { CardHeader, ICardHeader } from './cardHeader/CardHeader';
import { useStyles } from './Card.styles';

interface ICardProps {
  header?: ICardHeader;
  disableMaxHeight?: boolean;
}

export const Card: React.FC<ICardProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      {props.header && <CardHeader {...props.header} />}
      <div className={`${classes.content} ${!props.disableMaxHeight ? classes.scroll : ''}`}>{props.children}</div>
    </div>
  );
};
