import React from 'react';
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Block, HomeRounded, InfoRounded } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { useStyles } from './SideMenu.styles';
import { SideMenuHeader } from './SideMenuHeader/SideMenuHeader';
import { SideMenuFooter } from './SideMenuFooter/SideMenuFooter';

interface ISidemenu {
  category: string;
  items: IItems[];
}

interface IItems {
  icon: React.ReactNode;
  title: string;
  link: string;
}

const sidemenuItems: ISidemenu[] = [
  {
    category: 'Välkommen',
    items: [
      {
        icon: <HomeRounded fontSize="small" />,
        title: 'Startsida',
        link: '/dashboard',
      },
      {
        icon: <InfoRounded fontSize="small" />,
        title: 'Information',
        link: '/about',
      },
    ],
  },
  {
    category: 'Mina tjänster',
    items: [
      {
        icon: <Block fontSize="small" />,
        title: 'Spärrtjänster',
        link: '/blockservice',
      },
    ],
  },
];

interface ISidemenuProps {
  closeMenu?: () => void;
}

export const Sidemenu = (props: ISidemenuProps) => {
  const classes = useStyles();

  return (
    <>
      <SideMenuHeader closeMenu={props.closeMenu} />
      <Divider />
      <div className={classes.menuContainer}>
        {sidemenuItems.map((x: ISidemenu) => (
          <React.Fragment key={x.category}>
            <label className={classes.label}>{x.category}</label>
            <List disablePadding>
              {x.items.map((item: IItems) => (
                <ListItem disableGutters className={classes.listItem} key={item.title}>
                  <NavLink
                    to={item.link}
                    onClick={props.closeMenu}
                    activeClassName={classes.activeLink}
                    className={classes.link}
                  >
                    <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.title} />
                  </NavLink>
                </ListItem>
              ))}
            </List>
          </React.Fragment>
        ))}
      </div>
      <SideMenuFooter />
    </>
  );
};
